import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {selectUser} from "../../redux/reducers/user";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LoadingIndicator from "../LoadingIndicator";

export default ({placeringsgruppe}) => {
  const classes = useStyles();

  const user = useSelector(selectUser);
  const rangliste = useSelector(state => state.rangliste);
  if (!rangliste || !rangliste.rangliste || !rangliste.rangliste.placeringer)
    return <LoadingIndicator/>


  let placeringer = rangliste.rangliste.placeringer.filter(function (placering) {
    if (placeringsgruppe !== 'Alle') {
      return placering.placeringMinGruppe >= 0;
    } else {
      return !!(placering && placering.antalMedarbejdere > 40);
    }
  });

  return (
      <Fragment>
        <Table size="small">
          <TableBody>
            {placeringer.map((row, index) => (
                <TableRow key={row.pengeinstitut.id} selected={row.pengeinstitut.id === user.userinfo.pengeinstitut.id}>
                  <TableCell style={{width:80}} className={row.pengeinstitut.id === user.userinfo.pengeinstitut.id ? classes.currentBank : null}>
                    {placeringsgruppe === 'Alle' ? row.placeringAlle : row.placeringMinGruppe}
                  </TableCell>
                  <TableCell className={row.pengeinstitut.id === user.userinfo.pengeinstitut.id ? classes.currentBank : null}>
                    {row.pengeinstitut.navn}
                  </TableCell>
                  <TableCell>
                    {row.pengeinstitut.id === user.userinfo.pengeinstitut.id && <FontAwesomeIcon icon={faUser}/>}
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </Fragment>
  );
};

const useStyles = makeStyles({
  paper: {
    marginTop: 8
  },
  currentBank: {
    fontWeight: 'bold'
  }
});

