import React, {Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import SalgPrUge from "./SalgPrUge";
import SalgPrMaaned from "./SalgPrMaaned";
import SalgPrMaanedTop5 from "./SalgPrMaanedTop5";
import BestandPrMaaned from "./BestandPrMaaned";
import NysalgFordeling from "./NysalgFordeling";
import Livrente30Pct from "./Livrente30Pct";

export default ({produktGruppeNavn}) => {

  return (
      <Fragment>
        <Grid container spacing={2}>
          <SalgPrUge produktGruppeNavn={produktGruppeNavn}/>
          <SalgPrMaaned produktGruppeNavn={produktGruppeNavn}/>
          <SalgPrMaanedTop5 produktGruppeNavn={produktGruppeNavn}/>
          <BestandPrMaaned produktGruppeNavn={produktGruppeNavn} vaegtet={false} title="Udvikling i bestand sammenlignet med sidste år, i"/>
          <BestandPrMaaned produktGruppeNavn={produktGruppeNavn} vaegtet={true} title="Udvikling i bestand pr. 100 medarbejdere sammenlignet med sidste år, i"/>
          {produktGruppeNavn === 'Pension' && <NysalgFordeling enhed="styk"/>}
          {produktGruppeNavn === 'Pension' && <NysalgFordeling enhed="mio. kr."/>}
          {produktGruppeNavn === 'Pension' && <Livrente30Pct/>}
        </Grid>
      </Fragment>
  );
};
