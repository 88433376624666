import React from 'react';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {useSelector} from "react-redux";

import {makeStyles} from "@material-ui/styles";

import {selectUser} from "../redux/reducers/user";

import NotFoundPage from "./NotFoundPage";
import Profile from "./Profile";
import Bankliste from "./Bankliste";
import Login from "./login/Login";
import LoggingIn from "./login/LoggingIn";
import Container from "@material-ui/core/Container";
import Placering from "./placering/Placering";
import Indtjening from "./indtjening/Indtjening";
import Oversigt from "./oversigt/Oversigt";
import Salg from "./salg/Salg";
import Aktivitet from "./aktivitet/Aktivitet";
import {Swipeable} from "react-swipeable";
import {withSizes} from "react-sizes";

const MainContent = ({location, history, width}) => {

    const swipeHandler = (eventData) => {
        var swipedInTabsSection = eventData.event.path.filter(function (a) { return (a !== undefined && a.classList !== undefined && a.classList.contains("MuiTab-root")) })
        if (swipedInTabsSection.length > 0) {
            return;
        }

        const {dir} = eventData;
    let pathname = location.pathname;
    if (dir === 'Left') {
      switch (pathname) {
        case '/placering':
          history.push('/indtjening');
          break;
        case '/indtjening':
          history.push('/oversigt');
          break;
        case '/oversigt':
          history.push('/salg');
          break;
        case '/salg':
          history.push('/aktivitet');
          break;
        default:
      }
    }
    if (dir === 'Right') {
      switch (pathname) {
        case '/indtjening':
          history.push('/placering');
          break;
        case '/oversigt':
          history.push('/indtjening');
          break;
        case '/salg':
          history.push('/oversigt');
          break;
        case '/aktivitet':
          history.push('/salg');
          break;
        default:
      }
    }
  }

  const user = useSelector(selectUser);
  const classes = useStyles(width);
  return (
      <Swipeable onSwipedLeft={swipeHandler} onSwipedRight={swipeHandler} className={classes.swipeable}>
        <Container maxWidth="xl" className={classes.content}>

          {user.loggedIn === false && user.isLoggingIn === false && <Login/>}
          {user.loggedIn === false && user.isLoggingIn === true && <LoggingIn/>}

          {user.loggedIn === true && (
              <Switch>
                <Route exact path="/">
                  <Redirect to="/oversigt"/>
                </Route>
                <Route path="/placering" component={Placering}/>
                <Route path="/indtjening" component={Indtjening}/>
                <Route path="/oversigt" component={Oversigt}/>
                <Route path="/salg" component={Salg}/>
                <Route path="/aktivitet" component={Aktivitet}/>
                <Route path="/profile" component={Profile}/>
                <Route path="/bankliste" component={Bankliste}/>
                <Route component={NotFoundPage}/>
              </Switch>
          )}
        </Container>
      </Swipeable>
  );
};

const mapSizesToProps = ({width, height}) => ({
  width,
  height
})

export default withRouter(withSizes(mapSizesToProps)(MainContent));

const useStyles = makeStyles({
  content: width => {
    //We can't rely on the provided sizes in iOS. Therefore we use document...
    let clientWidth = document.documentElement.clientWidth;

    let paddingBottom = 70;
    if (clientWidth > 960)
      paddingBottom = 0;

    return {
      flexGrow: 1,
      height: "100vh",
      paddingTop: 130,
      paddingBottom: paddingBottom,
      overflow: "auto",
      WebkitOverflowScrolling: "touch"
    }
  },

  swipeable: {
    width: '100%'
  }
});
