import React from 'react';
import {makeStyles} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";

export default ({children, header, footer}) => {
  const classes = useStyles();

  return (
      <Grid item xs={12} md={6} lg={4}>
        <div className={classes.paper}>
          <Typography className={classes.paperHeader}>{header}</Typography>
          {children}
          <Typography className={classes.paperFooter}>{footer}</Typography>
        </div>
      </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    position: 'relative',
    minHeight:284,
    border:'1px solid #dddddd'
  },
  paperHeader: {
    fontSize: '1em',
    minHeight: 46
  },
  paperFooter: {
    fontSize: '.7em',
    color:'#666666',
    position: 'absolute',
    width: '100%',
    bottom: 0
  }
}));
