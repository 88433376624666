import React, {Fragment} from 'react';
import {makeStyles} from "@material-ui/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartBar, faDollarSign, faTachometerAlt, faTrophy, faUsers} from "@fortawesome/free-solid-svg-icons";
import ListItemText from "@material-ui/core/ListItemText";
import AdapterLink from "../../letlib/AdapterLink";
import {withRouter} from "react-router-dom";

const LpnDrawer = ({location}) => {
    const classes = useStyles();
    return (
        <Fragment>
            < Drawer
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.appBarSpacer}/>
                <List>
                    <ListItem button component={AdapterLink} to="/oversigt"
                              selected={location.pathname === '/oversigt'}
                              className="menuListItem">
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faTachometerAlt}/>
                        </ListItemIcon>
                        <ListItemText primary="Oversigt"/>
                    </ListItem>
                    <ListItem button component={AdapterLink} to="/placering"
                              selected={location.pathname === '/placering'}
                              className="menuListItem">
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faTrophy}/>
                        </ListItemIcon>
                        <ListItemText primary="Placering"/>
                    </ListItem>
                    <ListItem button component={AdapterLink} to="/indtjening"
                              selected={location.pathname === '/indtjening'}
                              className="menuListItem">
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faDollarSign}/>
                        </ListItemIcon>
                        <ListItemText primary="Indtjening"/>
                    </ListItem>
                    <ListItem button component={AdapterLink} to="/salg" selected={location.pathname === '/salg'}
                              className="menuListItem">
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faChartBar}/>
                        </ListItemIcon>
                        <ListItemText primary="Salg"/>
                    </ListItem>
                    <ListItem button component={AdapterLink} to="/aktivitet"
                              selected={location.pathname === '/aktivitet'}
                              className="menuListItem">
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faUsers}/>
                        </ListItemIcon>
                        <ListItemText primary="Aktivitet"/>
                    </ListItem>
                </List>
            </Drawer>
        </Fragment>
    );
}

export default withRouter(LpnDrawer);

const drawerWidth = 300;
const useStyles = makeStyles(theme => ({
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        color: '#FFFFFFFF',
        backgroundColor: '#6bb8bc',
        borderRight: 'none',
        paddingLeft: 0
    },
    appBarSpacer: {
        minHeight: 100,
    },
}));
