import LogRocket from 'logrocket';
import {createSelector} from 'reselect'
import {buildAuthenticationToken} from "../../util/ApiUtils";

import {
  REQUEST_LOGIN,
  RECEIVE_USERINFO,
  LOGIN_ERROR,
  LOGOUT
} from '../actions/user'

let initialState = {
  loggedIn: false,
  isLoggingIn: false,
  loginError: null,
  lastLogin: null,
  username: "",
  authenticationToken: null,
  userinfo: null,
};
export default function user(
    state = initialState,
    action
) {
  switch (action.type) {
    case REQUEST_LOGIN:
      return {
        ...state,
        isLoggingIn: true,
        loggedIn: false,
        username: action.username,
        userinfo: null,
      }
    case RECEIVE_USERINFO:
      let authenticationToken = state.authenticationToken;
      if (action.credentials) {
        authenticationToken = buildAuthenticationToken(action.credentials.username, action.credentials.password)
      }

      LogRocket.identify(action.userinfo.brugernavn, {
        name: action.userinfo.navn,
        bank: action.userinfo.pengeinstitut.navn
      });

      return {
        ...state,
        isLoggingIn: false,
        loggedIn: true,
        userinfo: action.userinfo,
        authenticationToken: authenticationToken
      }
    case LOGIN_ERROR:
      return {
        ...state,
        isLoggingIn: false,
        loggedIn: false,
        userinfo: null,
        loginError: action.loginError,
      }
    case LOGOUT:
      return {
        ...state,
        isLoggingIn: false,
        loggedIn: false,
        userinfo: null,
        loginError: null,
      }
    default:
      return state;
  }
}

//Selectors

export const selectUser = createSelector(
    state => state.user,
    user => user
)

export const selectPengeinstitut = createSelector(
    state => state.user.userinfo,
    userinfo => {
      if (!userinfo) return null;
      let pengeinstitut = userinfo.pengeinstitut;
      return pengeinstitut;
    }
)
