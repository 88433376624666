import {SALG_ACTIVE_PLACERING, SALG_ACTIVE_TAB} from '../actions/ui'

let initialState = {
  salg: {
    activeTab: 0
  },
  placering: {
    activeTab: 0
  }
};
export default function ui(
    state = initialState,
    action
) {
  switch (action.type) {
    case SALG_ACTIVE_TAB:
      return {
        ...state,
        salg: {
          activeTab: action.activeTab
        }
      }
    case SALG_ACTIVE_PLACERING:
      return {
        ...state,
        placering: {
          activeTab: action.activeTab
        }
      }
    default:
      return state;
  }
}

