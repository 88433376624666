export const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    let error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export const buildHeader = (token) => {
  let headers = {'Content-Type': 'application/json;charset=UTF-8',};
  headers.Accept = 'application/json';
  headers.Authorization = 'Basic ' + token;

  //console.log('Headers: ' + JSON.stringify(headers));
  return headers;
}

export const buildAuthenticationToken = (username, password) => {
  return btoa(username + ':' + password);
}


export const getThen = (url, user, header) => {
  return fetch(url + "?pengeinstitutId=" + user.userinfo.pengeinstitut.id,
      {
        method: 'GET',
        headers: header
      },
  )
      .then(checkStatus)
      .then;
}
