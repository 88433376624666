import React from 'react';
import {useSelector} from "react-redux";
import DateUtils from "../../util/DateUtils";
import LpnPieChart from "../charts/LpnPieChart";
import IndtjeningBox from "../indtjening/IndtjeningBox";
import LoadingIndicator from "../LoadingIndicator";
import Typography from "@material-ui/core/Typography";
import {formatNumber} from "../../util/FormatUtils";
import {makeStyles} from "@material-ui/styles";

export default () => {
  const classes = useStyles();

  const aktivitet = useSelector(state => state.aktivitet);
  if (!aktivitet || !aktivitet.aktivitet)
    return <LoadingIndicator/>

  let periodeAktiviteter = aktivitet.aktivitet.periodeAktivitet;
  let senesteAktivitetsPeriode = periodeAktiviteter[periodeAktiviteter.length - 1];

  let antalUdenBehov = 0;
  let antalOpsparing = 0;
  let antalNedsparing = 0;
  let antalFormue = 0;
  let total = 0;

  for (let i = 0; i < periodeAktiviteter.length; i++) {
    if (periodeAktiviteter[i].aar === senesteAktivitetsPeriode.aar) {

      let periodeAktivitet = periodeAktiviteter[i];
      antalUdenBehov += periodeAktivitet.SumAntalForslagUdenBehov;
      antalOpsparing += periodeAktivitet.SumAntalOpsparingForslag;
      antalNedsparing += periodeAktivitet.SumAntalNedsparingForslag;
      antalFormue += periodeAktivitet.SumAntalFormueForslag;
      total = antalUdenBehov + antalOpsparing + antalNedsparing + antalFormue;
    }
  }
  let decimals = 0;
  if (total < 100)
    decimals = 1;
  if (total < 10)
    decimals = 2;


  const data = [
    {name: 'Uden behov', value: antalUdenBehov},
    {name: 'Pension og forsikring', value: antalOpsparing},
    {name: 'Planlæg pensionering', value: antalNedsparing},
    {name: 'Formue', value: antalFormue},
  ]

  return (
      <IndtjeningBox
          header="Fordeling af rådgivningstype i det samlede antal forslag, stk., ÅTD"
          footer={"Beregnet pr. " + DateUtils.lpMaanedAar(aktivitet.aktivitet.beregningsDato)}
          className={classes.box}
      >
        <LpnPieChart data={data}/>
        <Typography className={classes.label}>{formatNumber(total, decimals)} styk</Typography>
      </IndtjeningBox>
  );
};

const useStyles = makeStyles({
  box: {
    position: 'relative'
  },
  label: {
    position: 'absolute',
    top: 60,
    right: 20
  }
});

