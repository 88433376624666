import {faChartBar, faDollarSign, faTachometerAlt, faTrophy, faUsers} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import {makeStyles} from "@material-ui/styles";
import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";

const BottomNav = ({location, history}) => {
    const classes = useStyles();
    let pathname = location.pathname;
    if (pathname === '/')
        pathname = '/oversigt';
    const [value, setValue] = useState(pathname);

    useEffect(() => {
        setValue(pathname)
    }, [pathname]);

    return (
        <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
                history.push(newValue);
            }}
            showLabels
            className={classes.bottomNav}
        >
            <BottomNavigationAction
                className={classes.bottomNavAction}
                label="Placering"
                value="/placering"
                icon={<FontAwesomeIcon icon={faTrophy}/>}
            />
            <BottomNavigationAction
                className={classes.bottomNavAction}
                label="Indtjening"
                value="/indtjening"
                icon={<FontAwesomeIcon icon={faDollarSign}/>}
            />
            <BottomNavigationAction
                className={classes.bottomNavAction}
                label="Oversigt"
                value="/oversigt"
                icon={<FontAwesomeIcon icon={faTachometerAlt}/>}
            />
            <BottomNavigationAction
                className={classes.bottomNavAction}
                label="Salg"
                value="/salg"
                icon={<FontAwesomeIcon icon={faChartBar}/>}
            />
            <BottomNavigationAction
                className={classes.bottomNavAction}
                label="Aktivitet"
                value="/aktivitet"
                icon={<FontAwesomeIcon icon={faUsers}/>}
            />
        </BottomNavigation>
    );
};

export default withRouter(BottomNav);

const useStyles = makeStyles(theme => ({
    bottomNav: {
        position: "fixed",
        bottom: 0,
        width: "100%",
        backgroundColor: '#6bb8bc',
        color: 'white',
    },
    bottomNavAction: {
        minWidth: 75,
        color: 'white',
    }
}));
