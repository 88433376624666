export const SALG_ACTIVE_TAB = 'SALG_ACTIVE_TAB';
export const SALG_ACTIVE_PLACERING = 'SALG_ACTIVE_PLACERING';

export function setActiveTabSalg(activeTab) {
  return {
    type: SALG_ACTIVE_TAB,
    activeTab: activeTab
  }
}

export function setActiveTabPlacering(activeTab) {
  return {
    type: SALG_ACTIVE_PLACERING,
    activeTab: activeTab
  }
}
