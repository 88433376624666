import {makeStyles} from "@material-ui/core";
import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";

export default () => {
  const classes = useStyles();
  return (
      <div className={classes.loadingIndicatorContainer}>
        <CircularProgress/>
      </div>
  );
};

const useStyles = makeStyles({
  loadingIndicatorContainer: {
    width: "100%",
    height: 200,
    textAlign: 'center',
    paddingTop: 50
  }
});
