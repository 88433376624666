//import Moment from '../../node_modules/react-moment/';
//import 'moment-timezone';

var DateUtils = {
    lpLongDate: function(datoString) {
        let dato = new Date(datoString);

          var day = dato.getDate();
          var monthIndex = dato.getMonth();
          var year = dato.getFullYear();

          return day + '. ' + this.maanedsNavne()[monthIndex] + ' ' + year;
    },
    maanedsNavne: function() {
          var monthNames = [
            "januar", "februar", "marts",
            "april", "maj", "juni", "juli",
            "august", "september", "oktober",
            "november", "december"
          ];
          return monthNames;
    },
    kortMaanedNavn: function(maanedNummer) {
          var maanedNavn = this.maanedsNavne()[maanedNummer];
          return maanedNavn.substring(0,3);
    },
    lpMaanedAar: function(datoString) {
        let dato = new Date(datoString);
        return this.kortMaanedNavn(dato.getMonth()) + " " + dato.getFullYear();
    }
};

export { DateUtils as default };
