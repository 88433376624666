
import {
  HENT_UDVIKLING,
  MODTAG_UDVIKLING,
  INVALIDER_UDVIKLING,
} from '../actions/udvikling'
import {RECEIVE_USERINFO} from "../actions/user";


export default function udvikling(
        state = {
            indlaeser: false,
            invalideret: false,
            fejlmeddelelse: null,
            udvikling: null,
            expire: null
        },
        action
    )
{
  switch (action.type) {
    case HENT_UDVIKLING:
      return Object.assign({}, state, {
        indlaeser: true,
        invalideret: false,
        fejlmeddelelse: null,
        udvikling: null,
        expire: null
      })
    case MODTAG_UDVIKLING:
        var t = new Date();
      return Object.assign({}, state, {
        indlaeser: false,
        invalideret: false,
        fejlmeddelelse: action.fejlmeddelelse,
        udvikling: action.udvikling,
        expire: t.setSeconds(t.getSeconds() + (3600))
      })
    case RECEIVE_USERINFO:
    case INVALIDER_UDVIKLING:
      return Object.assign({}, state, {
        indlaeser: false,
        fejlmeddelelse: null,
        invalideret: true,
        udvikling: null,
        expire: null
      })
    default:
      return state;
  }
}


