import React, {Fragment} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import LoadingIndicator from "../LoadingIndicator";
import SalgPrRaadgiver from "./SalgPrRaadgiver";
import {indlaesAktivitet} from "../../redux/actions/aktivitet";
import SalgPrProduktType from "./SalgPrProduktType";
import Raadgivningstype from "./Raadgivningstype";

export default () => {
  const dispatch = useDispatch();
  const aktivitet = useSelector(state => state.aktivitet);

  if (aktivitet.expire < new Date() && !aktivitet.indlaeser) {
    dispatch(indlaesAktivitet());
  }

  if (!aktivitet.aktivitet)
    return <LoadingIndicator/>;

  return (
      <Fragment>
        <Grid container spacing={2}>
          <SalgPrRaadgiver/>
          <SalgPrProduktType/>
          <Raadgivningstype/>
        </Grid>
      </Fragment>
  );
};
