import {useSelector} from "react-redux";

export const summerIndbetaling = (maanedSalg, periodeFra, periodeTil, indbetalingstype) => {
  let sum = 0;
  if (maanedSalg) {
    for (var i = 0; i < maanedSalg.length; i++) {
      if (new Date(maanedSalg[i].periodeFra) >= periodeFra && new Date(maanedSalg[i].periodeFra) <= periodeTil) {
        sum += indbetalingstype === 'loebende' ? maanedSalg[i].sumLoebendeIndbetaling : maanedSalg[i].sumIndskud;
      }
    }
  }
  return sum;
}

export const useMaanedsalg = (produktGruppeNavn) => {
  const udvikling = useSelector(state => state.udvikling);
  const salgProduktGrupper = udvikling.udvikling.salgProduktGrupper;
  let maanedSalg = salgProduktGrupper[0].maanedSalg;
  for (let i = 0; i < salgProduktGrupper.length; i++) {
    if (salgProduktGrupper[i].salgProduktGruppeNavn === produktGruppeNavn) {
      maanedSalg = salgProduktGrupper[i].maanedSalg;
    }
  }
  return maanedSalg;
}

