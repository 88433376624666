import React from 'react';
import {useSelector} from "react-redux";
import DateUtils from "../../util/DateUtils";
import LpnPieChart from "../charts/LpnPieChart";
import IndtjeningBox from "./IndtjeningBox";
import LoadingIndicator from "../LoadingIndicator";
import Typography from "@material-ui/core/Typography";
import {formatNumber} from "../../util/FormatUtils";
import {makeStyles} from "@material-ui/styles";

export default () => {
  const classes = useStyles();

  const indtjening = useSelector(state => state.indtjening);
  if (!indtjening || !indtjening.indtjening || !indtjening.indtjening.indtjeningProduktGrupper)
    return <LoadingIndicator/>

  let indtjeningProduktGrupper = indtjening.indtjening.indtjeningProduktGrupper;

  let total = 0;
  let data = indtjeningProduktGrupper.map(item => {
    total += item.samletIndtjening / 1000000 || 0;
    return {
      name: item.indtjeningProduktGruppeNavn,
      value: item.samletIndtjening / 1000000
    }
  })
  let decimals = 0;
  if (total < 100)
    decimals = 1;
  if (total < 10)
    decimals = 2;


  return (
      <IndtjeningBox
          header="Forventet helårlig indtjening fordelt på produkttype, i mio. kr."
          footer={"Beregnet pr. " + DateUtils.lpLongDate(indtjening.indtjening.beregningsDato)}
          className={classes.box}
      >
        <LpnPieChart data={data} decimals={'auto'}/>
        <Typography className={classes.label}>{formatNumber(total, decimals)} mio. kr.</Typography>
      </IndtjeningBox>
  );
};

const useStyles = makeStyles({
  box: {
    position: 'relative'
  },
  label: {
    position: 'absolute',
    top: 60,
    right: 20
  }
});

