import {
  HENT_RANGLISTE,
  MODTAG_RANGLISTE,
  INVALIDER_RANGLISTE,
} from '../actions/rangliste'
import {RECEIVE_USERINFO} from "../actions/user";


export default function rangliste(
    state = {
      indlaeser: false,
      invalideret: false,
      fejlmeddelelse: null,
      rangliste: null,
      expire: null
    },
    action
) {
  switch (action.type) {
    case HENT_RANGLISTE:
      return {
        indlaeser: true,
        invalideret: false,
        fejlmeddelelse: null,
        rangliste: null,
        expire: null
      }
    case MODTAG_RANGLISTE:
      let t = new Date();
      return {
        indlaeser: false,
        invalideret: false,
        fejlmeddelelse: action.fejlmeddelelse,
        rangliste: action.rangliste,
        expire: t.setSeconds(t.getSeconds() + (3600))
      }
    case RECEIVE_USERINFO:
    case INVALIDER_RANGLISTE:
      return {
        indlaeser: false,
        fejlmeddelelse: null,
        invalideret: true,
        rangliste: null,
        expire: null
      }
    default:
      return state;
  }
}
