import {buildHeader, checkStatus} from '../../util/ApiUtils'

export const HENT_UDVIKLING = 'HENT_UDVIKLING';
export const MODTAG_UDVIKLING = 'MODTAG_UDVIKLING';
export const INVALIDER_UDVIKLING = 'INVALIDER_UDVIKLING';

export function hentUdvikling() {
  return {
    type: HENT_UDVIKLING
  }
}

function modtagUdvikling(state, json, fejl) {
  return {
    type: MODTAG_UDVIKLING,
    udvikling: json,
    receivedAt: Date.now(),
    fejlmeddelelse: fejl
  }
}

export function indlaesUdvikling() {

  return (dispatch, getState) => {

    dispatch(hentUdvikling())
    let url = '/api/udvikling';

    let user = getState().user;

    let header = buildHeader(user.authenticationToken);

    return fetch(url + "?pengeinstitutId=" + user.userinfo.pengeinstitut.id,
        {
          method: 'GET',
          headers: header
        },
    )
        .then(checkStatus)
        .then(response =>
                response.json()
                    .then(json => {
                      dispatch(modtagUdvikling(getState(), json), null)
                    }),
            error => {
              dispatch(modtagUdvikling(getState(), null, 'Fejl ved indlæsning af udvikling'))
            })
  }
}


