import React from 'react';
import {useSelector} from "react-redux";
import DateUtils from "../../util/DateUtils";
import LpnPieChart from "../charts/LpnPieChart";
import IndtjeningBox from "../indtjening/IndtjeningBox";
import {summerIndbetaling, useMaanedsalg} from "./SalgUtils";
import Typography from "@material-ui/core/Typography";
import {formatNumber} from "../../util/FormatUtils";
import {makeStyles} from "@material-ui/styles";

export default () => {
  const classes = useStyles();

  const udvikling = useSelector(state => state.udvikling);

  let tildato = new Date(udvikling.udvikling.beregningsDato);
  let fradato = new Date(tildato.getFullYear(), 0, 1);

  let maanedSalgPension = useMaanedsalg('Pension');
  let maanedSalg30Pct = useMaanedsalg('Livrente30Pct');

  let samletIndbetalingPension = 0;
  if (maanedSalgPension) {
    let loebendeIndbetaling = summerIndbetaling(maanedSalgPension, fradato, tildato, 'loebende');
    let indskud = summerIndbetaling(maanedSalgPension, fradato, tildato, 'indskud');
    samletIndbetalingPension = loebendeIndbetaling + indskud;
  }

  let samletIndbetaling30Pct = 0;
  if (maanedSalg30Pct) {
    let loebendeIndbetaling = summerIndbetaling(maanedSalg30Pct, fradato, tildato, 'loebende');
    let indskud = summerIndbetaling(maanedSalg30Pct, fradato, tildato, 'indskud');
    samletIndbetaling30Pct = loebendeIndbetaling + indskud;
  }

  const data = [
    {
      name: '30%-ordninger',
      value: samletIndbetaling30Pct
    },
    {
      name: 'Øvrige indbetalinger',
      value: samletIndbetalingPension - samletIndbetaling30Pct
    }
  ]
  const total = (data[0].value + data[1].value) / 1000000;
  let decimals = 0;
  if (total < 100)
    decimals = 1;
  if (total < 10)
    decimals = 2;

  return (
      <IndtjeningBox
          header="Andel af indbetalinger fra 30 %-ordninger, i mio. kr"
          footer={"Beregnet pr. " + DateUtils.lpLongDate(udvikling.udvikling.beregningsDato)}
          className={classes.box}
      >
        <LpnPieChart data={data} height={300} divisor={1000000} decimals={'auto'}/>
        <Typography className={classes.label}>{formatNumber(total, decimals)} mio. kr.</Typography>
      </IndtjeningBox>
  );
};

const useStyles = makeStyles({
  box: {
    position: 'relative'
  },
  label: {
    position: 'absolute',
    top: 60,
    right: 20
  }
});

