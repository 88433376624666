import {buildHeader, checkStatus} from '../../util/ApiUtils'


export const HENT_AKTIVITET = 'HENT_AKTIVITET';
export const MODTAG_AKTIVITET = 'MODTAG_AKTIVITET';
export const INVALIDER_AKTIVITET = 'INVALIDER_AKTIVITET';

export function hentAktivitet() {
  return {
    type: HENT_AKTIVITET
  }
}

function modtagAktivitet(state, json, fejl) {
//    console.log('Action modtagAktivitet: '  + fejl);
  return {
    type: MODTAG_AKTIVITET,
    aktivitet: json,
    receivedAt: Date.now(),
    fejlmeddelelse: fejl
  }
}


export function indlaesAktivitet() {

  return (dispatch, getState) => {
    dispatch(hentAktivitet())
    let url = '/api/aktivitet';

    let user = getState().user;

    let header = buildHeader(user.authenticationToken);

    return fetch(url + "?pengeinstitutId=" + user.userinfo.pengeinstitut.id,
        {
          method: 'GET',
          headers: header
        },
    )
        .then(checkStatus)
        .then(response =>
                response.json()
                    .then(json => {
                      dispatch(modtagAktivitet(getState(), json), null)
                    }),
            error => {
              dispatch(modtagAktivitet(getState(), null, 'Fejl ved indlæsning af aktivitet'))
            })
  }
}


