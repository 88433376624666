import Grid from "@material-ui/core/Grid";
import React from "react";
import { useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import AppConfig from "../../app-config";
import DateUtils from "../../util/DateUtils";
import { formatNumber } from "../../util/FormatUtils";
import { palette, renderCustomizedLegend } from "../../util/ChartUtils";
import IndtjeningBox from "../indtjening/IndtjeningBox";

export default () => {
  const udvikling = useSelector((state) => state.udvikling);

  if (!udvikling.udvikling) return null;

  let bestandTotaler = udvikling.udvikling.bestandTotaler;
  bestandTotaler.sort((a, b) => (a.bestandDato > b.bestandDato ? 1 : -1));

  let senesteBestandTotaler = bestandTotaler[bestandTotaler.length - 1];
  let senesteBestandTotalerDato = new Date(senesteBestandTotaler.bestandDato);
  let sidsteAarBestandTotalerDato = new Date(
    senesteBestandTotalerDato.getFullYear() - 1,
    senesteBestandTotalerDato.getMonth(),
    senesteBestandTotalerDato.getDate()
  );
  let bestandTotalerSidsteAarIndex = 1;

  for (let i = 0; i < bestandTotaler.length; i++) {
    let bestandTotalerDato = new Date(bestandTotaler[i].bestandDato);
    if (
      sidsteAarBestandTotalerDato.getTime() === bestandTotalerDato.getTime()
    ) {
      bestandTotalerSidsteAarIndex = i;
    }
  }

  let totaler = [];
  totaler.push(bestandTotaler[bestandTotalerSidsteAarIndex] || {});
  totaler.push(senesteBestandTotaler);

  const aar = new Date(udvikling.udvikling.beregningsDato).getFullYear();
  const sidsteAar =
    new Date(udvikling.udvikling.beregningsDato).getFullYear() - 1;

  const dataPension = [
    {
      name: sidsteAar,
      value: totaler[0].sumOpsparetVaerdiLivrente / 1000000,
    },
    {
      name: aar,
      value: totaler[1].sumOpsparetVaerdiLivrente / 1000000,
    },
  ];

  const dataDoed = [
    {
      name: sidsteAar,
      value: totaler[0].antalDoed,
    },
    {
      name: aar,
      value: totaler[1].antalDoed,
    },
  ];

  const dataSygdom = [
    {
      name: sidsteAar,
      value: totaler[0].antalSygdom,
    },
    {
      name: aar,
      value: totaler[1].antalSygdom,
    },
  ];

  const renderCustomizedLegendPension = () => {
    return renderCustomizedLegend("Pension, mio. kr.");
  };

  const renderCustomizedLegendDoed = () => {
    return renderCustomizedLegend("Død, stk.");
  };

  const renderCustomizedLegendSygdom = () => {
    return renderCustomizedLegend("Sygdom, stk.");
  };

  return (
    <IndtjeningBox
      header="Udvikling i bestand sammenlignet med sidste år"
      footer={
        "Beregnet pr. " +
        DateUtils.lpLongDate(udvikling.udvikling.beregningsDato)
      }
    >
      <Grid container>
        <Grid item xs={4}>
          <ResponsiveContainer height={220}>
            <BarChart data={dataPension} barGap={0}>
              <Legend
                verticalAlign="top"
                content={renderCustomizedLegendPension}
              />
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="name"
                interval={0}
                height={30}
                angle={45}
                textAnchor="start"
                tick={{ fontSize: 9 }}
              />
              <YAxis
                tickFormatter={formatNumber}
                allowDecimals={false}
                tick={{ fontSize: 9 }}
                width={42}
              />
              <Bar
                dataKey="value"
                fill={palette[0]}
                isAnimationActive={AppConfig.animateCharts}
              />
            </BarChart>
          </ResponsiveContainer>
        </Grid>
        <Grid item xs={4}>
          <ResponsiveContainer height={220}>
            <BarChart data={dataDoed} barGap={0}>
              <Legend
                verticalAlign="top"
                content={renderCustomizedLegendDoed}
              />
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="name"
                interval={0}
                height={30}
                angle={45}
                textAnchor="start"
                tick={{ fontSize: 9 }}
              />
              <YAxis
                tickFormatter={formatNumber}
                allowDecimals={false}
                tick={{ fontSize: 9 }}
                width={42}
              />
              <Bar
                dataKey="value"
                fill={palette[1]}
                isAnimationActive={AppConfig.animateCharts}
              />
            </BarChart>
          </ResponsiveContainer>
        </Grid>
        <Grid item xs={4}>
          <ResponsiveContainer height={220}>
            <BarChart data={dataSygdom} barGap={0}>
              <Legend
                verticalAlign="top"
                content={renderCustomizedLegendSygdom}
              />
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="name"
                interval={0}
                height={30}
                angle={45}
                textAnchor="start"
                tick={{ fontSize: 9 }}
              />
              <YAxis
                tickFormatter={formatNumber}
                allowDecimals={false}
                tick={{ fontSize: 9 }}
                width={42}
              />
              <Bar
                dataKey="value"
                fill={palette[2]}
                isAnimationActive={AppConfig.animateCharts}
              />
            </BarChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </IndtjeningBox>
  );
};
