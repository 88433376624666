import React from 'react';
import {useSelector} from "react-redux";
import DateUtils from "../../util/DateUtils";
import LpnPieChart from "../charts/LpnPieChart";
import IndtjeningBox from "../indtjening/IndtjeningBox";
import {summerIndbetaling, useMaanedsalg} from "./SalgUtils";
import Typography from "@material-ui/core/Typography";
import {formatNumber} from "../../util/FormatUtils";
import {makeStyles} from "@material-ui/styles";

export default ({enhed}) => {
  const classes = useStyles();
  const udvikling = useSelector(state => state.udvikling);

  let tildato = new Date(udvikling.udvikling.beregningsDato);
  let fradato = new Date(tildato.getFullYear(), 0, 1);

  let maanedSalg = useMaanedsalg('Pension');

  const summerSalgstal = (maanedSalg, periodeFra, periodeTil, indbetalingstype) => {
    let sum = 0;
    if (maanedSalg) {
      for (let i = 0; i < maanedSalg.length; i++) {
        if (new Date(maanedSalg[i].periodeFra) >= periodeFra && new Date(maanedSalg[i].periodeFra) <= periodeTil) {
          sum += indbetalingstype === 'loebende' ? maanedSalg[i].antalLoebendeIndbetaling : maanedSalg[i].antalIndskud;
        }
      }
    }
    return sum;
  }

  const data = [
    {
      name: 'Løbende',
      value: enhed === 'styk' ? summerSalgstal(maanedSalg, fradato, tildato, 'loebende') :
          summerIndbetaling(maanedSalg, fradato, tildato, 'loebende')
    },
    {
      name: 'Indskud',
      value: enhed === 'styk' ? summerSalgstal(maanedSalg, fradato, tildato, 'indskud') :
          summerIndbetaling(maanedSalg, fradato, tildato, 'indskud')
    }
  ]
  let total = data[0].value + data[1].value;
  total = total / (enhed === 'styk' ? 1 : 1000000);

  let decimals = 0;
  if (total < 100)
    decimals = 1;
  if (total < 10)
    decimals = 2;

  return (
      <IndtjeningBox
          header={"Nysalg fordelt på indbetalingstype, år til dato, i " + enhed}
          footer={"Beregnet pr. " + DateUtils.lpLongDate(udvikling.udvikling.beregningsDato)}
          className={classes.box}
      >
        <LpnPieChart data={data}
                     height={300}
                     divisor={enhed === 'styk' ? 1 : 1000000}
                     decimals={enhed === 'styk' ? 0 : 'auto'}
        />
        <Typography className={classes.label}>{formatNumber(total, decimals)} {enhed}</Typography>
      </IndtjeningBox>
  );
};

const useStyles = makeStyles({
  box: {
    position: 'relative'
  },
  label: {
    position: 'absolute',
    top: 60,
    right: 20
  }
});

