import React, {Fragment} from 'react';
import Typography from "@material-ui/core/Typography";
import {useDispatch, useSelector} from "react-redux";
import {indlaesRangliste} from "../../redux/actions/rangliste";
import {setActiveTabPlacering} from "../../redux/actions/ui";
import PlaceringListe from "./PlaceringListe";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import LoadingIndicator from "../LoadingIndicator";
import {selectUser} from "../../redux/reducers/user";

export default () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const rangliste = useSelector(state => state.rangliste);
  const uiPlacering = useSelector(state => state.ui.placering);

  if (rangliste.expire < new Date() && !rangliste.indlaeser) {
    dispatch(indlaesRangliste());
  }

  if (!rangliste.rangliste)
    return <LoadingIndicator/>;

  const handleTabChange = (event, newValue) => {
    dispatch(setActiveTabPlacering(newValue));
  };

  return (
      <Fragment>
        <Typography style={{fontSize:16}}>Hvem klarer sig bedst i Letpension-samarbejdet, baseret på det totale salg pr. 100 medarbejdere</Typography>
        <br/><br/>

        {
          user.userinfo.pengeinstitut.regnr !== "0000" &&
          <Fragment>
            <Tabs value={uiPlacering.activeTab} onChange={handleTabChange}>
              <Tab label="Alle"/>
              {user.userinfo.pengeinstitut.regnr !== "0000" && <Tab label="Sammenlignelige"/>}
            </Tabs>
            <br/>
            {uiPlacering.activeTab === 0 && <PlaceringListe placeringsgruppe="Alle"/>}
            {uiPlacering.activeTab === 1 && <PlaceringListe placeringsgruppe="Min gruppe"/>}
          </Fragment>
        }

        {
          user.userinfo.pengeinstitut.regnr === "0000" &&
          <PlaceringListe placeringsgruppe="Alle"/>
        }

      </Fragment>
  );
};
