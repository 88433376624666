import {useState} from "react";

export const useGenericForm = (initialValues, callback) => {
  const [values, setValues] = useState(initialValues);
  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    callback(values);
  }
  const handleInputChange = (event) => {
    event.persist();
    setValues(values => ({...values, [event.target.name]: event.target.value}));
  }
  return {
    handleSubmit,
    handleInputChange,
    values
  }
}

