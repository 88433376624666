import './polyfills'; //Must be imported as first line!!!!
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import React from 'react';
import {render} from 'react-dom';
import {AppContainer} from 'react-hot-loader';
import AppConfig from './app-config';
import Root from './components/Root';
import configureStore from './redux/configureStore';
import * as serviceWorker from './serviceWorker';
import './index.css';

if (AppConfig && AppConfig.env !== 'local') {
  if (AppConfig && AppConfig.logRocketAppId)
    LogRocket.init(AppConfig.logRocketAppId, {
      release: AppConfig.buildNumber
    });

  setupLogRocketReact(LogRocket);
}

const store = configureStore();

console.log('Launching build number ' + AppConfig.buildNumber);

render(
    <AppContainer>
      <Root store={store}/>
    </AppContainer>,
    document.getElementById('root')
);

if (module.hot) {
  module.hot.accept('./components/Root', () => {
    const NewRoot = require('./components/Root').default;
    render(
        <AppContainer>
          <NewRoot store={store}/>
        </AppContainer>,
        document.getElementById('root')
    );
  });
}

const hash = (s) => {
  let hash = 0, i, chr;
  if (s.length === 0) return hash;
  for (i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

let knownVersion = null;
let newVersionAvailable = false;

const checkVersion = () => {
  if (process.env.NODE_ENV === 'production') {
    fetch('asset-manifest.json')
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
              let jsonString = JSON.stringify(myJson);
              let newVersion = hash(jsonString);
              if (knownVersion != null && newVersion !== knownVersion) {
                newVersionAvailable = true;
              }
              knownVersion = newVersion;
              if (newVersionAvailable) {
                if (document.getElementById('newVersionSnackBar'))
                  document.getElementById('newVersionSnackBar').style.display = 'block';
                if (document.getElementById('newVersionReload'))
                  document.getElementById('newVersionReload').addEventListener('click', function () {
                    window.location.reload();
                  });
              }
            }
        )
        .catch(() => {
          console.log('Error loading asset-manifest');
        });
  }
}

const listenForAppActivated = () => {
  let hidden;
  let visibilityChange;
  if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }

  window.document.addEventListener(visibilityChange, () => {
    if (!document[hidden]) {
      checkVersion();
    }
  });

};

if (navigator.userAgent.match(/Mobi/)) {
  console.log('Mobile. Using serviceworker');
  serviceWorker.register();
} else {
  console.log('Desktop. Not using serviceworker');
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(
        function (registrations) {
          for (let registration of registrations) {
            registration.unregister();
          }
        });
  }
  checkVersion();
  listenForAppActivated();
  setInterval(checkVersion, 30 * 60 * 1000);

}
