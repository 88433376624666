import { combineReducers } from 'redux'

import user from './user'
import rangliste from './rangliste'
import indtjening from './indtjening'
import udvikling from './udvikling'
import aktivitet from './aktivitet'
import ui from './ui'

const rootReducer = combineReducers({
    user,
    rangliste,
    indtjening,
    udvikling,
    aktivitet,
    ui
})

export default rootReducer
