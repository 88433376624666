import React from 'react';
import {useSelector} from "react-redux";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis} from "recharts";
import AppConfig from "../../app-config";
import DateUtils from "../../util/DateUtils";
import {palette} from "../../util/ChartUtils";
import IndtjeningBox from "../indtjening/IndtjeningBox";
import LoadingIndicator from "../LoadingIndicator";

export default () => {
  const aktivitet = useSelector(state => state.aktivitet);
  if (!aktivitet || !aktivitet.aktivitet)
    return <LoadingIndicator/>


  let data = aktivitet.aktivitet.periodeAktivitet.map(item => {
    return {
      name: DateUtils.kortMaanedNavn(item.maaned-1),
      value1: item.antalRaadgivereMed1Til2Aftaler,
      value3: item.antalRaadgivereMed3Til5Aftaler,
      value5: item.antalRaadgivereMedMereEnd5
    }
  })

  return (
      <IndtjeningBox
          header="Antal rådgivere, der har solgt en eller flere Letsikringer de seneste 12 måneder. Letsikring af lån og kredit er IKKE inkluderet."
          footer={"Beregnet pr. " + DateUtils.lpMaanedAar(aktivitet.aktivitet.beregningsDato)}
      >
        <ResponsiveContainer height={200}>
          <BarChart data={data} barGap={0}>
            <CartesianGrid vertical={false}/>
            <XAxis dataKey="name" interval={0} height={30} angle={45} textAnchor="start" tick={{fontSize: 9}}/>
            <YAxis/>
            <Bar dataKey="value1" fill={palette[0]} name="1-2 aftaler" isAnimationActive={AppConfig.animateCharts}/>
            <Bar dataKey="value3" fill={palette[1]} name="3-5 aftaler" isAnimationActive={AppConfig.animateCharts}/>
            <Bar dataKey="value5" fill={palette[2]} name="5+ aftaler" isAnimationActive={AppConfig.animateCharts}/>
            <Legend verticalAlign="top" height={40}/>
          </BarChart>
        </ResponsiveContainer>
      </IndtjeningBox>
  );
};
