import React, { Fragment } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useDispatch, useSelector } from "react-redux";
import { clearCache, logout } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import { selectUser } from "../redux/reducers/user";

function Profile({ history }) {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const classes = useStyles();

  const handleLogout = () => {
    dispatch(logout());
    history.push("/");
  };

  const handleClearCache = () => {
    dispatch(clearCache());
    history.push("/");
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Letpension Nøgletal
        </Typography>
        <br />
        <div>Logget ind som:</div>
        <div className={classes.userInfo}>{user.username}</div>
        <br />
        <div>Pengeinstitut:</div>
        <div className={classes.userInfo}>
          {user.userinfo.pengeinstitut.navn}
        </div>
        <br />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleLogout}
          style={{ color: "white" }}
        >
          Log ud
        </Button>
        {(user.username === "xxz" ||
          user.username === "xxw" ||
          user.username === "yub" ||
          user.username === "dhl") && (
          <Fragment>
            <br />
            <Button fullWidth variant="contained" onClick={handleClearCache}>
              Clear cache
            </Button>
          </Fragment>
        )}
      </div>
    </Container>
  );
}

export default withRouter(Profile);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  userInfo: {
    fontWeight: "bold",
  },
}));
