import {buildHeader, checkStatus} from '../../util/ApiUtils'

export const HENT_INDTJENING = 'HENT_INDTJENING'
export const MODTAG_INDTJENING = 'MODTAG_INDTJENING'
export const INVALIDER_INDTJENING = 'INVALIDER_INDTJENING'

export function hentIndtjening() {
  return {
    type: HENT_INDTJENING
  }
}

function modtagIndtjening(state, json, fejl) {
  return {
    type: MODTAG_INDTJENING,
    indtjening: json,
    receivedAt: Date.now(),
    fejlmeddelelse: fejl
  }
}

export function indlaesIndtjening() {

  return (dispatch, getState) => {

    dispatch(hentIndtjening())
    let url = '/api/indtjening';

    let user = getState().user;

    let header = buildHeader(user.authenticationToken);

    return fetch(url + "?pengeinstitutId=" + user.userinfo.pengeinstitut.id,
        {
          method: 'GET',
          headers: header
        },
    )
        .then(checkStatus)
        .then(response =>
                response.json()
                    .then(json => {
                      dispatch(modtagIndtjening(getState(), json), null)
                    }),
            error => {
              dispatch(modtagIndtjening(getState(), null, 'Fejl ved indlæsning af indtjening'))
            })
  }
}


