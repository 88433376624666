
import {
  HENT_AKTIVITET,
  MODTAG_AKTIVITET,
  INVALIDER_AKTIVITET,
} from '../actions/aktivitet'
import {RECEIVE_USERINFO} from "../actions/user";


export default function aktivitet(
        state = {
            indlaeser: false,
            invalideret: false,
            fejlmeddelelse: null,
            aktivitet: null,
            expire: null
        },
        action
    )
{
  switch (action.type) {
    case HENT_AKTIVITET:
      return Object.assign({}, state, {
        indlaeser: true,
        invalideret: false,
        fejlmeddelelse: null,
        aktivitet: null,
        expire: null
      })
    case MODTAG_AKTIVITET:
        var t = new Date();
      return Object.assign({}, state, {
        indlaeser: false,
        invalideret: false,
        fejlmeddelelse: action.fejlmeddelelse,
        aktivitet: action.aktivitet,
        expire: t.setSeconds(t.getSeconds() + (3600))
      })
    case RECEIVE_USERINFO:
    case INVALIDER_AKTIVITET:
      return Object.assign({}, state, {
        indlaeser: false,
        fejlmeddelelse: null,
        invalideret: true,
        aktivitet: null,
        expire: null
      })
    default:
      return state;
  }
}


