import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from '@material-ui/core/styles';
import {selectUser} from "../redux/reducers/user";
import {userLogin} from "../redux/actions/user";
import LpnAppBar from "./layout/LpnAppBar";
import LpnDrawer from "./layout/LpnDrawer";
import MainContent from "./MainContent";
import Hidden from "@material-ui/core/Hidden";
import BottomNav from "./layout/BottomNav";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import SnackbarContent from "@material-ui/core/SnackbarContent";

export default () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const classes = useStyles();

  //Autologin
  if (user.loggedIn === false && user.isLoggingIn === false && !user.loginError) {
    const credentials = window.localStorage.getItem('credentials');
    if (credentials) {
      dispatch(userLogin(JSON.parse(credentials)));
    }
  }

  return (
      <div className={classes.root}>
        <LpnAppBar/>
        <Hidden smDown>
          {user.loggedIn && <LpnDrawer/>}
        </Hidden>
        <MainContent/>
        <Hidden mdUp>
          {user.loggedIn && <BottomNav/>}
        </Hidden>
        <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={true}
            className={classes.snackbar}
            id="newVersionSnackBar"
        >
          <SnackbarContent
              className={classes.snackbarContent}
              message={<span id="message-id">Der er en ny version af denne app</span>}
              action={[
                <Button key="undo" size="small" id="newVersionReload">
                  Opdater
                </Button>,
              ]}
          />
        </Snackbar>

      </div>
  );
}

const useStyles = makeStyles({
  root: {
    display: 'flex'
  },
  snackbar: {
    display: 'none'
  },
  snackbarContent: {
    backgroundColor: '#f26d21'
  }
});
