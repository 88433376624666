import React from 'react';
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis} from "recharts";
import AppConfig from "../../app-config";
import {palette} from "../../util/ChartUtils";
import {formatNumber} from "../../util/FormatUtils";

export default ({data}) => {

  return (
      <ResponsiveContainer height={180}>
        <BarChart data={data}>
          <CartesianGrid vertical={false}/>
          <XAxis dataKey="name"/>
          <YAxis tickFormatter={formatNumber} allowDecimals={false}/>
          <Bar dataKey="value" fill={palette[0]}
               maxBarSize={50} isAnimationActive={AppConfig.animateCharts}/>
        </BarChart>
      </ResponsiveContainer>
  );
};
