import React, {Fragment} from 'react';
import {makeStyles} from "@material-ui/styles";
import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import LoadingIndicator from "../LoadingIndicator";

export default props => {
  const classes = useStyles();
  const {header, footer, figure, diffValue, loading} = props;
  return (
      <Grid item xs={6} lg={3}>
        <div className={classes.paper}>
          {(loading === true) && <LoadingIndicator/>}

          {(loading === false) &&
          <Fragment>
            <Typography className={classes.paperHeader}>{header}</Typography>
            <Typography className={classes.paperFigure}>
              {diffValue !== undefined && diffValue !== 0 && <DiffIndicator value={diffValue}/>}
              {figure}
            </Typography>
            <Typography className={classes.paperFooter}>{footer}</Typography>
          </Fragment>
          }

        </div>
      </Grid>
  );
}

const DiffIndicator = ({value}) => {
  const classes = useStyles();

  const renderUp = (renderValue) => {
    return (
        <Fragment>
          <ArrowDropUpIcon className={classes.diffUpIcon}/>
          <span className={classes.diffUpValue}>{renderValue}</span>
        </Fragment>
    );
  }


  const renderDown = (renderValue) => {
    return (
        <Fragment>
          <span className={classes.diffDownValue}>{renderValue}</span>
          <ArrowDropDownIcon className={classes.diffDownIcon}/>
        </Fragment>
    );
  }

  return (
      value && value <= 0 ? renderDown(value) : renderUp(value)
  )
}


const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    height: 126,
    border:'1px solid #dddddd'

  },
  paperHeader: {
    fontSize: '0.9em',
    height: 46
  },
  paperFooter: {
    fontSize: '0.7em',
    height: 24
  },
  paperFigure: {
    fontSize: '1.4em',
    height: 40
  },
  diffDownIcon: {
    textAlign: 'center',
    color: 'red',
    position: 'relative',
    top: 10,
    left: -5
  },
  diffDownValue: {
    position: 'relative',
    fontSize: 12,
    top: -10,
    left: 12
  },
  diffUpIcon: {
    textAlign: 'center',
    color: 'green',
    position: 'relative',
    top: -4
  },
  diffUpValue: {
    fontSize: 12,
    position: 'relative',
    left: -16
  }
}));
