import React, { Fragment } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { setActiveTabSalg } from "../../redux/actions/ui";
import LoadingIndicator from "../LoadingIndicator";
import { indlaesUdvikling } from "../../redux/actions/udvikling";
import SalgOversigt from "./SalgOversigt";
import SalgDetail from "./SalgDetail";
import { makeStyles } from "@material-ui/styles";
import { withSizes } from "react-sizes";

const Salg = ({ sizes }) => {
    const classes = useStyles(sizes);
    const dispatch = useDispatch();
    const udvikling = useSelector(state => state.udvikling);
    const uiSalg = useSelector(state => state.ui.salg);

    if (udvikling.expire < new Date() && !udvikling.indlaeser) {
        dispatch(indlaesUdvikling());
    }

    if (!udvikling.udvikling)
        return <LoadingIndicator />;

    const handleTabChange = (event, newValue) => {
        dispatch(setActiveTabSalg(newValue));
    };

    return (
        <Fragment>
            <Tabs value={uiSalg.activeTab} onChange={handleTabChange} className={classes.tabs} variant="scrollable"
    scrollButtons="auto">
                <Tab label="Oversigt" />
                <Tab label="Pension" />
                <Tab label="Død" />
                <Tab label="Sygdom" />
                <Tab label="Letsikring til selvstændige" />
            </Tabs>
            <div className={classes.tabContent}>
                {uiSalg.activeTab === 0 && <SalgOversigt />}
                {uiSalg.activeTab === 1 && <SalgDetail produktGruppeNavn="Pension" />}
                {uiSalg.activeTab === 2 && <SalgDetail produktGruppeNavn="Doed" />}
                {uiSalg.activeTab === 3 && <SalgDetail produktGruppeNavn="SygdomSamlet" />}
                {uiSalg.activeTab === 4 && <SalgDetail produktGruppeNavn="LTS" />}
            </div>
        </Fragment>
    );
};

const mapSizesToProps = (sizes) => ({
    sizes
})

export default withSizes(mapSizesToProps)(Salg);

const useStyles = makeStyles({
    tabs: {
        marginBottom: 8
    },
    tabContent: sizes => {
        //We can't rely on the provided sizes in iOS. Therefore we use document...
        let width = document.documentElement.clientWidth;
        let height = document.documentElement.clientHeight;
        let offset = 206;
        if (width > 960)
            offset = 150;
        return {
            height: height - offset,
            overflowY: 'auto',
            overflowX: 'hidden'
        }
    }
}
);
