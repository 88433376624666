
import {
  HENT_INDTJENING,
  MODTAG_INDTJENING,
  INVALIDER_INDTJENING,
} from '../actions/indtjening'
import {RECEIVE_USERINFO} from "../actions/user";


export default function indtjening(
        state = {
            indlaeser: false,
            invalideret: false,
            fejlmeddelelse: null,
            indtjening: null,
            expire: null
        },
        action
    )
{
  switch (action.type) {
    case HENT_INDTJENING:
      return Object.assign({}, state, {
        indlaeser: true,
        invalideret: false,
        fejlmeddelelse: null,
        indtjening: null,
        expire: null
      })
    case MODTAG_INDTJENING:
        var t = new Date();
      return Object.assign({}, state, {
        indlaeser: false,
        invalideret: false,
        fejlmeddelelse: action.fejlmeddelelse,
        indtjening: action.indtjening,
        expire: t.setSeconds(t.getSeconds() + (3600))
      })
    case RECEIVE_USERINFO:
    case INVALIDER_INDTJENING:
      return Object.assign({}, state, {
        indlaeser: false,
        fejlmeddelelse: null,
        invalideret: true,
        indtjening: null,
        expire: null
      })
    default:
      return state;
  }
}


