import React from 'react';
import {useSelector} from "react-redux";
import {CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis} from "recharts";
import Bar from "recharts/es6/cartesian/Bar";
import BarChart from "recharts/es6/chart/BarChart";
import AppConfig from "../../app-config";
import {getEnhed, palette} from "../../util/ChartUtils";
import DateUtils from "../../util/DateUtils";
import {formatNumber} from "../../util/FormatUtils";
import IndtjeningBox from "../indtjening/IndtjeningBox";
import {useMaanedsalg} from "./SalgUtils";

export default ({produktGruppeNavn}) => {
  const udvikling = useSelector(state => state.udvikling);

  let maanedSalg = useMaanedsalg(produktGruppeNavn);

  let data = [];
  for (let i = maanedSalg.length - 1; i > maanedSalg.length - 14; i--) {
    let periodeFra = new Date(maanedSalg[i].periodeFra);
    let maanedNummer = periodeFra.getMonth();

    let indevaerendeAar = maanedSalg[i].antal;
    if (produktGruppeNavn === 'Pension')
      indevaerendeAar = maanedSalg[i].beloeb / 1000000;

    let forrigeAar = 0;
    if (i >= 12) {
      forrigeAar = maanedSalg[i - 12].antal;
      if (produktGruppeNavn === 'Pension')
        forrigeAar = maanedSalg[i - 12].beloeb / 1000000;
    }

    data.unshift({
      name: DateUtils.kortMaanedNavn(maanedNummer),
      forrigeAar: forrigeAar,
      indevaerendeAar: indevaerendeAar
    });
  }

  let enhed = getEnhed(produktGruppeNavn);

  return (
      <IndtjeningBox
          header={"Salg pr. måned det seneste år sammenlignet med forrige år, i " + enhed}
          footer={"Beregnet pr. " + DateUtils.lpLongDate(udvikling.udvikling.beregningsDato)}
      >
        <ResponsiveContainer height={300}>
          <BarChart data={data} barGap={0}>
            <CartesianGrid vertical={false}/>
            <XAxis dataKey="name" interval={0} height={36} angle={45} textAnchor="start"/>
            <YAxis tickFormatter={formatNumber} allowDecimals={false}/>
            <Legend verticalAlign="top" height={40} iconType="square"/>
            <Bar dataKey="forrigeAar" fill={palette[0]} name="Forrige 12 mdr" isAnimationActive={AppConfig.animateCharts}/>
            <Bar dataKey="indevaerendeAar" fill={palette[1]} name="Seneste 12 mdr" isAnimationActive={AppConfig.animateCharts}/>
          </BarChart>
        </ResponsiveContainer>
      </IndtjeningBox>
  );
};
