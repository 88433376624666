import React from "react";
import { useSelector } from "react-redux";
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import Bar from "recharts/es6/cartesian/Bar";
import BarChart from "recharts/es6/chart/BarChart";
import AppConfig from "../../app-config";
import DateUtils from "../../util/DateUtils";
import { formatNumber } from "../../util/FormatUtils";
import { getEnhed, palette } from "../../util/ChartUtils";
import IndtjeningBox from "../indtjening/IndtjeningBox";

export default ({ produktGruppeNavn, vaegtet, title }) => {
  const udvikling = useSelector((state) => state.udvikling);

  let bestandTotaler = vaegtet
    ? udvikling.udvikling.bestandTotalerVaegtet
    : udvikling.udvikling.bestandTotaler;
  bestandTotaler.sort((a, b) => (a.bestandDato > b.bestandDato ? 1 : -1));

  let data = [];
  for (let i = bestandTotaler.length - 1; i > bestandTotaler.length - 14; i--) {
    if (!bestandTotaler[i]) continue;

    let bestandDato = new Date(bestandTotaler[i].bestandDato);
    let maanedNummer = bestandDato.getMonth();

    let indevaerendeAar = bestandTotaler[i].antalDoed;
    if (produktGruppeNavn === "Sygdom" || produktGruppeNavn === "SygdomSamlet")
      indevaerendeAar = bestandTotaler[i].antalSygdom;

    if (produktGruppeNavn === "LTS") {
      indevaerendeAar = bestandTotaler[i].antalLTSAftaler;
    }

    if (produktGruppeNavn === "Pension")
      indevaerendeAar = bestandTotaler[i].sumOpsparetVaerdiLivrente / 1000000;

    let bestandTotal = 0;
    if (i >= 12) bestandTotal = bestandTotaler[i - 12];
    let forrigeAar = 0;
    if (bestandTotal) {
      forrigeAar = bestandTotal.antalDoed;
      if (
        produktGruppeNavn === "Sygdom" ||
        produktGruppeNavn === "SygdomSamlet"
      )
        forrigeAar = bestandTotal.antalSygdom;
      if (produktGruppeNavn === "Pension")
        forrigeAar = bestandTotal.sumOpsparetVaerdiLivrente / 1000000;
    }

    data.unshift({
      name: DateUtils.kortMaanedNavn(maanedNummer),
      forrigeAar: forrigeAar,
      indevaerendeAar: indevaerendeAar,
    });
  }

  let enhed = getEnhed(produktGruppeNavn);

  return (
    <IndtjeningBox
      header={title + " " + enhed}
      footer={
        "Beregnet pr. " +
        DateUtils.lpLongDate(udvikling.udvikling.beregningsDato)
      }
    >
      <ResponsiveContainer height={300}>
        <BarChart data={data} barGap={0}>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="name"
            interval={0}
            height={36}
            angle={45}
            textAnchor="start"
          />
          <YAxis
            domain={["dataMin", "auto"]}
            tickFormatter={formatNumber}
            allowDecimals={false}
          />
          <Legend verticalAlign="top" height={40} iconType="square" />
          <Bar
            dataKey="forrigeAar"
            fill={palette[0]}
            name="Forrige 12 mdr"
            isAnimationActive={AppConfig.animateCharts}
          />
          <Bar
            dataKey="indevaerendeAar"
            fill={palette[1]}
            name="Seneste 12 mdr"
            isAnimationActive={AppConfig.animateCharts}
          />
        </BarChart>
      </ResponsiveContainer>
    </IndtjeningBox>
  );
};
