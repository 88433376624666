import React from 'react';
import {useSelector} from "react-redux";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis} from "recharts";
import AppConfig from "../../app-config";
import {selectUser} from "../../redux/reducers/user";
import DateUtils from "../../util/DateUtils";
import {formatNumber} from "../../util/FormatUtils";
import {palette} from "../../util/ChartUtils";
import IndtjeningBox from "./IndtjeningBox";
import LoadingIndicator from "../LoadingIndicator";

export default () => {
  const indtjening = useSelector(state => state.indtjening);
  const user = useSelector(selectUser);
  if (!indtjening || !indtjening.indtjening || !indtjening.indtjening.indtjeningProduktGrupper)
    return <LoadingIndicator/>

  let data = indtjening.indtjening.indtjeningProduktGrupper.map(item => {
    return {
      name: item.indtjeningProduktGruppeNavn,
      valueOwn: item.gennemsnitligIndtjening / 1000,
      valueTop: item.top5GennemsnitligIndtjening / 1000
    }
  })

  return (
      <IndtjeningBox
          header="Forventet helårlig indtjening pr. 100 medarbejdere sammenlignet med de fem pengeinstitutter, som tjener mest (forholdsmæssigt omregnet), i tusind kr."
          footer={"Beregnet pr. " + DateUtils.lpLongDate(indtjening.indtjening.beregningsDato)}
      >
        <ResponsiveContainer height={200}>
          <BarChart data={data} barGap={0}>
            <CartesianGrid vertical={false}/>
            <XAxis dataKey="name"/>
            <YAxis tickFormatter={formatNumber} allowDecimals={false}/>
            <Bar dataKey="valueOwn" fill={palette[0]} name={user.userinfo.pengeinstitut.navn} isAnimationActive={AppConfig.animateCharts}/>
            <Bar dataKey="valueTop" fill={palette[1]} name="Top 5" isAnimationActive={AppConfig.animateCharts}/>
            <Legend verticalAlign="top" height={40}/>
          </BarChart>
        </ResponsiveContainer>
      </IndtjeningBox>
  );
};
