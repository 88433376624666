const hostname = window && window.location && window.location.hostname;
const logRocketOrganizationSlug = '1tli98';
let env;

if (hostname === 'localhost') {
  env = 'local';
} else if (hostname === 'lpnoegletal-dev.azurewebsites.net') {
  env = 'dev';
} else if (hostname === 'lpnoegletal-test.azurewebsites.net') {
  env = 'test';
} else if (hostname === 'lpnoegletal-preprod.azurewebsites.net') {
  env = 'preprod';
} else if (hostname === 'lpnoegletal.dk' || hostname === 'www.lpnoegletal.dk' || hostname === 'lpnoegletal-prod.azurewebsites.net') {
  env = 'prod';
}

let AppConfig = {
  animateCharts: true,
  env,
  buildNumber: '20241015.2',
  logRocketAppId: logRocketOrganizationSlug + '/lpngletal-' + env
}

export default AppConfig;
