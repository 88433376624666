import {buildHeader, checkStatus} from "../../util/ApiUtils";

export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const RECEIVE_USERINFO = 'RECEIVE_USERINFO';
export const LOGOUT = 'LOGOUT';

export function requestLogin(user, userCredentials) {
  return {
    type: REQUEST_LOGIN,
    username: userCredentials.username
  }
}

function receiveUserinfo(state, userCredentials, json) {
  if (userCredentials) {
    window.localStorage.setItem('credentials', JSON.stringify(userCredentials));
  }
  return {
    type: RECEIVE_USERINFO,
    userinfo: json,
    credentials: userCredentials,
  }
}

function loginError(state, userCredentials, loginError) {
  return {
    type: LOGIN_ERROR,
    receivedAt: Date.now(),
    loginError: loginError
  }
}

export function userLogin(userCredentials) {
  return (dispatch, getState) => {

    if (getState().user.isLoggedIn) {
      return Promise.resolve();
    } else {
      dispatch(requestLogin(getState(), userCredentials))

      let url = '/api/login';

      return fetch(url,
          {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              brugernavn: userCredentials.username,
              password: userCredentials.password,
            })
          },
      )
          .then(checkStatus)
          .then(response =>
                  response.json()
                      .then(json => {
                        dispatch(receiveUserinfo(getState(), userCredentials, json))
                      })
                      .catch(error=>{
                          dispatch(loginError(getState(), userCredentials, 'Fejl i enten brugernavn eller password'));
                      }),
              error => {
                if (error.response != null && error.response.status === 401) {
                  dispatch(loginError(getState(), userCredentials, 'Fejl i enten brugernavn eller password'));
                } else {
                  dispatch(loginError(getState(), userCredentials, 'Der opstod en fejl - prøv igen senere'));
                }


              })
    }

  }
}

export function skiftpengeinstitut(pengeinstitut) {

  return (dispatch, getState) => {

    let url = '/api/changebank';
    let user = getState().user;
    let header = buildHeader(user.authenticationToken);
    return fetch(url,
        {
          method: 'POST',
          headers: header,
          body: JSON.stringify({
            id: pengeinstitut.id,
            navn: pengeinstitut.navn,
            regnr: pengeinstitut.regnr
          })
        },
    )
        .then(checkStatus)
        .then(response =>
                response.json()
                    .then(json => {
                      dispatch(receiveUserinfo(getState(), null, json));
                      window.history.back();
                    }),
            error => {
              if (error.response != null && error.response.status === 401) {
                dispatch(loginError(getState(), null, 'Fejl i enten brugernavn eller password'));
              } else {
                dispatch(loginError(getState(), null, 'Der opstod en fejl - prøv igen senere'));
              }
            }
        )
  }
}

export function logout() {
  window.localStorage.removeItem('credentials');
  return {
    type: LOGOUT
  }
}

export function clearCache() {

    return (dispatch, getState) => {
        let url = '/api/clearcache';
        let user = getState().user;
        let header = buildHeader(user.authenticationToken);
        return fetch(url,
            {
                method: 'POST',
                headers: header
            },
        )
            .then(checkStatus)
    }
}


