import React, {Fragment} from 'react';
import SalgUdvikling from "./SalgUdvikling";
import BestandUdvikling from "./BestandUdvikling";
import Grid from "@material-ui/core/Grid";

export default () => {

  return (
      <Fragment>
        <Grid container spacing={2}>
          <SalgUdvikling/>
          <BestandUdvikling/>
        </Grid>
      </Fragment>
  );
};
