import React, {Fragment} from 'react';
import {useSelector} from "react-redux";

import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from "@material-ui/styles";
import {Typography} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import logo from "../../images/letpension-logo.svg";
import AdapterLink from "../../letlib/AdapterLink";
import {selectPengeinstitut, selectUser} from "../../redux/reducers/user";
import Hidden from "@material-ui/core/Hidden";

export default () => {
    const user = useSelector(selectUser);
    const selectedBank = useSelector(selectPengeinstitut);
    const classes = useStyles();
    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <Hidden xsDown>
                    <img src={logo} alt="Letpension logo" style={{width: 250, height: 50}}/>
                </Hidden>
                {/*<Hidden smDown>*/}
                {/*    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>*/}
                {/*        Nøgletal*/}
                {/*    </Typography>*/}
                {/*</Hidden>*/}
                <div className={classes.grow}/>
                {user.loggedIn &&
                <Fragment>
                    {user.userinfo.muligepengeinstitutter.length > 1 &&
                    <Button
                        variant="contained"
                        color="primary"
                        component={AdapterLink} to="/bankliste"
                        style={{color:'white', marginRight:16}}
                    >
                        {selectedBank.navn}
                        <ArrowDropDownIcon className={classes.rightIcon}/>
                    </Button>
                    }
                    {user.userinfo.muligepengeinstitutter.length <= 1 &&
                    <Typography component="h1" variant="h6" color="inherit">
                        {selectedBank.navn}
                    </Typography>
                    }

                    <IconButton
                        color="primary"
                        edge="end"
                        component={AdapterLink} to="/profile"
                        style={{padding:0}}
                    >
                        <AccountCircle className={classes.accountIcon}/>
                    </IconButton>
                </Fragment>
                }

            </Toolbar>
        </AppBar>
    );
}

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    toolbar: {
        paddingRight: 40, // keep right padding when drawer closed
        minHeight: 100,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        // backgroundColor: '#323239',
        // backgroundColor: '#1e677b',
        // backgroundColor: '#5D7975',
        // backgroundColor: '#9d9fa2',
        backgroundColor: '#f6f0e7',
        boxShadow:'none'

    },
    title: {
        flexGrow: 1,
        fontSize: '1.8em',
        fontWeight:600,
        marginLeft: 130
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    accountIcon: {
        fontSize: 40,
    }
}));
