import React from 'react';
import {useSelector} from "react-redux";
import {CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis} from "recharts";
import Bar from "recharts/es6/cartesian/Bar";
import BarChart from "recharts/es6/chart/BarChart";
import AppConfig from "../../app-config";
import {selectUser} from "../../redux/reducers/user";
import {palette} from "../../util/ChartUtils";
import DateUtils from "../../util/DateUtils";
import IndtjeningBox from "../indtjening/IndtjeningBox";
import {useMaanedsalg} from "./SalgUtils";

export default ({produktGruppeNavn}) => {
  const udvikling = useSelector(state => state.udvikling);
  const user = useSelector(selectUser);

  let beregningsDato = new Date(udvikling.udvikling.beregningsDato);

  let maanedSalg = useMaanedsalg( produktGruppeNavn);

  let data = [];
  let sidsteAaarDato = new Date(beregningsDato.getFullYear() - 1, beregningsDato.getMonth(), 1);

  for (let i = 0; i < maanedSalg.length; i++) {
    let periodeFra = new Date(maanedSalg[i].periodeFra);
    if (periodeFra >= sidsteAaarDato) {

      let salg = maanedSalg[i].gennemsnitligSalg;
      if (produktGruppeNavn === 'Pension')
        salg = salg / 1000000;

      let top5 = maanedSalg[i].top5Salg;
      if (produktGruppeNavn === 'Pension')
        top5 = top5 / 1000000;

      data.push({
        name: DateUtils.kortMaanedNavn(periodeFra.getMonth()),
        salg: salg,
        top5: top5
      })
    }
  }

  return (
      <IndtjeningBox
          header="Salg pr. måned pr. 100 medarbejdere, sammenlignet med de fem bedst sælgende pengeinstitutter, i mio. kr."
          footer={"Beregnet pr. " + DateUtils.lpLongDate(udvikling.udvikling.beregningsDato)}
      >
        <ResponsiveContainer height={300}>
          <BarChart data={data} barGap={0}>
            <CartesianGrid vertical={false}/>
            <XAxis dataKey="name" interval={0} height={36} angle={45} textAnchor="start"/>
            <YAxis/>
            <Legend verticalAlign="top" height={40} iconType="square"/>
            <Bar dataKey="salg" fill={palette[0]} name={user.userinfo.pengeinstitut.navn} isAnimationActive={AppConfig.animateCharts}/>
            <Bar dataKey="top5" fill={palette[1]} name="Top 5" isAnimationActive={AppConfig.animateCharts}/>
          </BarChart>
        </ResponsiveContainer>
      </IndtjeningBox>
  );
};
