import React from 'react';
import {useSelector} from "react-redux";
import {CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis} from "recharts";
import Line from "recharts/es6/cartesian/Line";
import LineChart from "recharts/es6/chart/LineChart";
import AppConfig from "../../app-config";
import DateUtils from "../../util/DateUtils";
import {formatNumber} from "../../util/FormatUtils";
import {getEnhed, palette} from "../../util/ChartUtils";
import IndtjeningBox from "../indtjening/IndtjeningBox";

export default ({produktGruppeNavn}) => {
  const udvikling = useSelector(state => state.udvikling);

  if (!udvikling || !udvikling.udvikling || !udvikling.udvikling.beregningsDato){
    return;
  }

  let beregningsDato = new Date(udvikling.udvikling.beregningsDato);
  let indevaerendeAar = beregningsDato.getFullYear();
  let forrigeAar = indevaerendeAar - 1;

  const ugeSalgAar = (udvikling, produktGruppeNavn, aar) => {

    let salgProduktGrupper = udvikling.udvikling.salgProduktGrupper;

    let ugeSalg = salgProduktGrupper[0].ugeSalg;
    for (let i = 0; i < salgProduktGrupper.length; i++) {
      if (salgProduktGrupper[i].salgProduktGruppeNavn === produktGruppeNavn) {
        ugeSalg = salgProduktGrupper[i].ugeSalg;
      }
    }

    let ugeSalgAar = [];

    for (let i = 0; i < ugeSalg.length; i++) {
      let ugeSalgPeriodeFraDato = new Date(ugeSalg[i].periodeFra);
      if (ugeSalgPeriodeFraDato.getFullYear() === aar) {
        ugeSalgAar.push(ugeSalg[i]);
      }
    }
    return ugeSalgAar;
  }

  let ugeSalgIndevaerdendeAar = ugeSalgAar(udvikling, produktGruppeNavn, indevaerendeAar);
  let ugeSalgForrigeAar = ugeSalgAar(udvikling, produktGruppeNavn, forrigeAar);

  let data = [];
  let akkumuleretSalgForrigeAar = 0;
  let akkumuleretSalgIndevaerendeAar = 0;
  for (let i = 0; i < ugeSalgForrigeAar.length; i++) {

    if (produktGruppeNavn === 'Pension')
      akkumuleretSalgForrigeAar += ugeSalgForrigeAar[i].beloeb / 1000000;
    else
      akkumuleretSalgForrigeAar += ugeSalgForrigeAar[i].antal;

    if (ugeSalgIndevaerdendeAar[i])
      if (produktGruppeNavn === 'Pension')
        akkumuleretSalgIndevaerendeAar += ugeSalgIndevaerdendeAar[i].beloeb / 1000000;
      else
        akkumuleretSalgIndevaerendeAar += ugeSalgIndevaerdendeAar[i].antal;
    else
      akkumuleretSalgIndevaerendeAar = null;

    data.push({
      name: i + 1,
      forrigeAar: akkumuleretSalgForrigeAar,
      indevaerendeAar: akkumuleretSalgIndevaerendeAar
    })
  }

  let enhed = getEnhed(produktGruppeNavn);

  return (
      <IndtjeningBox
          header={"Samlet salg pr. uge sammenlignet med sidste år, i " + enhed}
          footer={"Beregnet pr. " + DateUtils.lpLongDate(udvikling.udvikling.beregningsDato)}
      >
        <ResponsiveContainer height={300}>
          <LineChart data={data}>
            <CartesianGrid/>
            <XAxis dataKey="name"/>
            <YAxis tickFormatter={formatNumber} allowDecimals={false}/>
            <Legend verticalAlign="top" height={40} iconType="square"/>
            <Line type="monotone" strokeWidth={2} dot={false} dataKey="forrigeAar" stroke={palette[0]} name={forrigeAar} isAnimationActive={AppConfig.animateCharts}/>
            <Line type="monotone" strokeWidth={2} dot={false} dataKey="indevaerendeAar" stroke={palette[1]} name={indevaerendeAar} isAnimationActive={AppConfig.animateCharts}/>
          </LineChart>
        </ResponsiveContainer>
      </IndtjeningBox>
  );
};
