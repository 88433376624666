import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import Typography from "@material-ui/core/Typography";

export default () => {
  return (
      <Fragment>
        <Typography variant="h4">404 Page Not Found</Typography>
        <Typography component={Link} to="/">Go back to homepage</Typography>
      </Fragment>
  );
};
