import Grid from "@material-ui/core/Grid";
import React from 'react';
import {useSelector} from "react-redux";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis} from "recharts";
import AppConfig from "../../app-config";
import {palette, renderCustomizedLegend} from "../../util/ChartUtils";
import DateUtils from "../../util/DateUtils";
import IndtjeningBox from "../indtjening/IndtjeningBox";
import {useMaanedsalg} from "./SalgUtils";

export default () => {
  const udvikling = useSelector(state => state.udvikling);

  if (!udvikling.udvikling)
    return null;

  const salgPension = useMaanedsalg('Pension');
  const dataPension = [
    {
      name: DateUtils.kortMaanedNavn(new Date(salgPension[salgPension.length - 3].periodeFra).getMonth()),
      value: salgPension[salgPension.length - 3].beloeb / 1000000
    },
    {
      name: DateUtils.kortMaanedNavn(new Date(salgPension[salgPension.length - 2].periodeFra).getMonth()),
      value: salgPension[salgPension.length - 2].beloeb / 1000000
    },
    {
      name: DateUtils.kortMaanedNavn(new Date(salgPension[salgPension.length - 1].periodeFra).getMonth()),
      value: salgPension[salgPension.length - 1].beloeb / 1000000
    }
  ]

  const salgDoed = useMaanedsalg('Doed');
  const dataDoed = [
    {
      name: DateUtils.kortMaanedNavn(new Date(salgDoed[salgDoed.length - 3].periodeFra).getMonth()),
      value: salgDoed[salgDoed.length - 3].antal
    },
    {
      name: DateUtils.kortMaanedNavn(new Date(salgDoed[salgDoed.length - 2].periodeFra).getMonth()),
      value: salgDoed[salgDoed.length - 2].antal
    },
    {
      name: DateUtils.kortMaanedNavn(new Date(salgDoed[salgDoed.length - 1].periodeFra).getMonth()),
      value: salgDoed[salgDoed.length - 1].antal
    }
  ]

  const salgSygdom = useMaanedsalg('Sygdom');
  const dataSygdom = [
    {
      name: DateUtils.kortMaanedNavn(new Date(salgSygdom[salgSygdom.length - 3].periodeFra).getMonth()),
      value: salgSygdom[salgSygdom.length - 3].antal
    },
    {
      name: DateUtils.kortMaanedNavn(new Date(salgSygdom[salgSygdom.length - 2].periodeFra).getMonth()),
      value: salgSygdom[salgSygdom.length - 2].antal
    },
    {
      name: DateUtils.kortMaanedNavn(new Date(salgSygdom[salgSygdom.length - 1].periodeFra).getMonth()),
      value: salgSygdom[salgSygdom.length - 1].antal
    }
  ]

  const renderCustomizedLegendPension = () => {
    return renderCustomizedLegend('Pension, mio. kr.');
  }

  const renderCustomizedLegendDoed = () => {
    return renderCustomizedLegend('Død, stk.');
  }

  const renderCustomizedLegendSygdom = () => {
    return renderCustomizedLegend('Sygdom, stk.');
  }

  return (
      <IndtjeningBox
          header="Samlet salg de sidste tre måneder"
          footer={"Beregnet pr. " + DateUtils.lpLongDate(udvikling.udvikling.beregningsDato)}
      >
        <Grid container>
          <Grid item xs={4}>
            <ResponsiveContainer height={220}>
              <BarChart data={dataPension} barGap={0}>
                <Legend verticalAlign="top" content={renderCustomizedLegendPension}/>
                <CartesianGrid vertical={false}/>
                <XAxis dataKey="name" interval={0} height={30} angle={45} textAnchor="start" tick={{fontSize: 9}}/>
                <YAxis tick={{fontSize: 9}} width={42}/>
                <Bar dataKey="value" fill={palette[0]} isAnimationActive={AppConfig.animateCharts}/>
              </BarChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={4}>
            <ResponsiveContainer height={220}>
              <BarChart data={dataDoed} barGap={0}>
                <Legend verticalAlign="top" content={renderCustomizedLegendDoed}/>
                <CartesianGrid vertical={false}/>
                <XAxis dataKey="name" interval={0} height={30} angle={45} textAnchor="start" tick={{fontSize: 9}}/>
                <YAxis tick={{fontSize: 9}} width={42}/>
                <Bar dataKey="value" fill={palette[1]} isAnimationActive={AppConfig.animateCharts}/>
              </BarChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={4}>
            <ResponsiveContainer height={220}>
              <BarChart data={dataSygdom} barGap={0}>
                <Legend verticalAlign="top" content={renderCustomizedLegendSygdom}/>
                <CartesianGrid vertical={false}/>
                <XAxis dataKey="name" interval={0} height={30} angle={45} textAnchor="start" tick={{fontSize: 9}}/>
                <YAxis tick={{fontSize: 9}} width={42}/>
                <Bar dataKey="value" fill={palette[2]} isAnimationActive={AppConfig.animateCharts}/>
              </BarChart>
            </ResponsiveContainer>
          </Grid>
        </Grid>
      </IndtjeningBox>
  );
};
