import React from "react";

export const palette = [
  '#6bb8bc',
  '#e49d3b',
  '#b9c35d',
  '#d84b59',
  '#a69f89',
  '#b8926f'
];

export const renderCustomizedLegend = (text) => {
  return <div style={{fontSize: 12, fontWeight: 500, marginBottom: 8}}>{text}</div>
}


export const getEnhed = (produktGruppeNavn) => {
  let enhed = 'styk';
  if (produktGruppeNavn === 'Pension')
    enhed = 'mio. kr.';
  return enhed;
}
