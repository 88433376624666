import {buildHeader, checkStatus} from "../../util/ApiUtils";

export const HENT_RANGLISTE = 'HENT_RANGLISTE';
export const MODTAG_RANGLISTE = 'MODTAG_RANGLISTE';
export const INVALIDER_RANGLISTE = 'INVALIDER_RANGLISTE';

export function hentRangliste() {
  return {
    type: HENT_RANGLISTE
  }
}

function modtagRangliste(state, json, fejl) {
  return {
    type: MODTAG_RANGLISTE,
    rangliste: json,
    receivedAt: Date.now(),
    fejlmeddelelse: fejl
  }
}

export function indlaesRangliste() {
  return (dispatch, getState) => {
    dispatch(hentRangliste());
    let url = '/api/rangliste';

    let user = getState().user;

    let header = buildHeader(user.authenticationToken);

    return fetch(url + "?pengeinstitutId=" + user.userinfo.pengeinstitut.id,
        {
          method: 'GET',
          headers: header,
        },
    )
        .then(checkStatus)
        .then(response =>
                response.json()
                    .then(json => {
                      dispatch(modtagRangliste(getState(), json), null)
                    }),
            error => {
              dispatch(modtagRangliste(getState(), null, 'Fejl ved indlæsning af rangliste'))
            })
  }
}


