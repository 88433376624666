import React from 'react';
import {Cell, Legend, Pie, PieChart, ResponsiveContainer} from "recharts";
import AppConfig from "../../app-config";
import {palette} from "../../util/ChartUtils";
import {formatNumber} from "../../util/FormatUtils";

export default ({data, height = 200, divisor = 1, decimals = 0}) => {

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
                                   cx, cy, midAngle, innerRadius, outerRadius, percent, index,
                                 }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.15;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const v = data[index].value / divisor;
    let d = 0;
    if (decimals === 'auto') {
      if (v < 100)
        d = 1;
      if (v < 10)
        d = 2;
    }

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {formatNumber(v, d)}
        </text>
    );
  };

  return (
      <ResponsiveContainer height={height}>
        <PieChart>
          <Legend align="center" verticalAlign="bottom"/>
          <Pie
              data={data}
              innerRadius={20}
              outerRadius={75}
              labelLine={false}
              label={renderCustomizedLabel}
              dataKey="value"
              nameKey="name"
              legendType="square"
              isAnimationActive={AppConfig.animateCharts}
          >
            {
              data.map((entry, index) => <Cell key={`cell-${index}`} fill={palette[index % palette.length]}/>)
            }
          </Pie>
        </PieChart>
      </ResponsiveContainer>
  );
};
