import React from 'react';
import Container from '@material-ui/core/Container';
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/styles";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => {
  return {
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      textAlign: 'center'
    }
  };
});

export default () => {
  const classes = useStyles();
  return (
      <Container className={classes.container} maxWidth="xs">
        <CircularProgress/>
        <Typography>Logger ind...</Typography>
      </Container>
  );
}
