import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import React from 'react';
import {useSelector} from "react-redux";
import {palette} from "../../util/ChartUtils";
import DateUtils from "../../util/DateUtils";
import {formatNumber} from "../../util/FormatUtils";
import LpnBarChart from "../charts/LpnBarChart";
import IndtjeningBox from "./IndtjeningBox";
import LoadingIndicator from "../LoadingIndicator";

export default () => {
  const classes = useStyles();

  const indtjening = useSelector(state => state.indtjening);

  if (!indtjening || !indtjening.indtjening || !indtjening.indtjening.indtjeningPerioder)
    return <LoadingIndicator/>;

  let indtjeningPeriode = indtjening.indtjening.indtjeningPerioder[indtjening.indtjening.indtjeningPerioder.length - 1];

  let egenIndtjening = indtjeningPeriode.samletIndtjening / 1000000;
  let indtjeningTop5 = indtjeningPeriode.top5GennemsnitligIndtjening / 1000000;

  let data = [
    {
      name: "Indtjening",
      value: egenIndtjening
    },
    {
      name: "Indtjening Top 5",
      value: indtjeningTop5
    }
  ]

  let potentiale = indtjeningTop5 - egenIndtjening
  if (potentiale < 0)
    potentiale = 0;

  return (
      <IndtjeningBox
          header="Forventet helårlig indtjening sammenlignet med de fem pengeinstitutter, som tjener mest (forholdsmæssigt omregnet), i mio. kr."
          footer={"Beregnet pr. " + DateUtils.lpLongDate(indtjening.indtjening.beregningsDato)}
      >
        <Grid container>
          <Grid item xs={6}>
            <LpnBarChart data={data}/>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.potentialeText}>Uudnyttet potentiale</Typography>
            <Typography>
                <span
                    className={classes.potentialeValue}>{formatNumber(potentiale, 1)} mio. kr.</span>
            </Typography>
          </Grid>
        </Grid>
      </IndtjeningBox>
  );
};

const useStyles = makeStyles({
  potentialeText: {
    paddingTop: 50
  },
  potentialeValue: {
    backgroundColor: palette[1],
    color: 'white',
    padding: '3px 16px',
    borderRadius: 14
  }
});

