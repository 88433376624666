import {applyMiddleware, compose, createStore} from 'redux'
import LogRocket from 'logrocket';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunkMiddleware from 'redux-thunk'
import rootReducer from './reducers'

const initialState = {};

// const breadcrumbMiddleware = store => next => action => {
//   const previousState = store.getState();
//   const nextAction = next(action);
//   const state = store.getState();
//
//   return nextAction;
// };

function createMiddleware() {
  let middleware = [];

  if (process.env.NODE_ENV !== 'production')
    middleware.push(reduxImmutableStateInvariant());

  middleware.push(
      thunkMiddleware,
      // breadcrumbMiddleware
      LogRocket.reduxMiddleware() //LogRocket middleware must be last
  )
  return middleware;
}

const configureStore = () => {

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
  const middleware = createMiddleware();

  let store = createStore(
      rootReducer,
      initialState,
      composeEnhancers(applyMiddleware(...middleware))
  );

  if (process.env.NODE_ENV !== 'production')
    if (module.hot) {
      module.hot.accept('./reducers', () => {
        store.replaceReducer(rootReducer);
      });
    }

  return store
}

export default configureStore;
