import React from 'react';
import Container from '@material-ui/core/Container';
import {useDispatch, useSelector} from "react-redux";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {skiftpengeinstitut} from "../redux/actions/user";

export default () => {
  const bankliste = [...useSelector(state => state.user.userinfo.muligepengeinstitutter)];
  const dispatch = useDispatch();

  const handleClick = (pengeinstitut) => {
    dispatch(skiftpengeinstitut(pengeinstitut))
  }

  bankliste.sort(function (a, b) {
    if (a.navn < b.navn) return -1;
    if (a.navn > b.navn) return 1;
    return 0;
  })

  return (
      <Container component="main" maxWidth="sm">
        <List>
          {bankliste.map(bank => <ListItem
              onClick={() => handleClick(bank)}
              button
              key={bank.id}
              value={bank.id}
          >{bank.navn}</ListItem>)}
        </List>
      </Container>
  );
}
