import React from 'react';
import {useSelector} from "react-redux";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis} from "recharts";
import AppConfig from "../../app-config";
import DateUtils from "../../util/DateUtils";
import {formatNumber} from "../../util/FormatUtils";
import {palette} from "../../util/ChartUtils";
import IndtjeningBox from "../indtjening/IndtjeningBox";
import LoadingIndicator from "../LoadingIndicator";

export default () => {
  const aktivitet = useSelector(state => state.aktivitet);
  if (!aktivitet || !aktivitet.aktivitet)
    return <LoadingIndicator/>

  let periodeAktiviteter = aktivitet.aktivitet.periodeAktivitet;
  let senesteAktivitetsPeriode = periodeAktiviteter[periodeAktiviteter.length - 1];

  let antalLivrente = 0;
  let antalBarn = 0;
  let antalLaanOgKredit = 0;
  let antalDoed = 0;
  let antalSygdom = 0;
  let antalKritiskSygdom = 0;
  let antalKapitalpension = 0;
  let antalRatepension = 0;
  let antalAldersopsparing = 0;

  for (let i = 0; i < periodeAktiviteter.length; i++) {
    if (periodeAktiviteter[i].aar === senesteAktivitetsPeriode.aar) {

      let periodeAktivitet = periodeAktiviteter[i];
      antalLivrente += periodeAktivitet.SumAntalLivrenter;
      antalBarn += periodeAktivitet.SumAntalBoernerenter;
      antalLaanOgKredit += periodeAktivitet.SumAntalLaanOgKredit;
      antalDoed += periodeAktivitet.SumAntalDoed;
      antalSygdom += periodeAktivitet.SumAntalSygdom;
      antalKritiskSygdom += periodeAktivitet.SumAntalKritiskSygdom;
      antalAldersopsparing += periodeAktivitet.SumAntalAldersopsparinger;
      antalKapitalpension += periodeAktivitet.SumAntalKapitalpensioner;
      antalRatepension += periodeAktivitet.SumAntalRatepensioner;
    }
  }

  const data = [
    {name: 'Livrenter', value: antalLivrente},
    {name: 'Børnerenter', value: antalBarn},
    {name: 'Lån og kredit', value: antalLaanOgKredit},
    {name: 'Død', value: antalDoed},
    {name: 'Sygdom', value: antalSygdom},
    {name: 'Kritisk sygdom', value: antalKritiskSygdom},
    {name: 'Aldersopsparing', value: antalAldersopsparing},
    {name: 'Kapitalpension', value: antalKapitalpension},
    {name: 'Rate', value: antalRatepension}
  ]
  return (
      <IndtjeningBox
          header="Fordeling af produktyper i det samlede antal forslag, år til dato"
          footer={"Beregnet pr. " + DateUtils.lpMaanedAar(aktivitet.aktivitet.beregningsDato)}
      >
        <ResponsiveContainer height={200}>
          <BarChart data={data}>
            <CartesianGrid vertical={false}/>
            <XAxis dataKey="name" interval={0} height={70} angle={45} textAnchor="start" tick={{fontSize: 9}}/>
            <YAxis tickFormatter={formatNumber} allowDecimals={false}/>
            <Bar dataKey="value" fill={palette[0]}
                 maxBarSize={50} isAnimationActive={AppConfig.animateCharts}/>
          </BarChart>
        </ResponsiveContainer>
      </IndtjeningBox>
  );
};
