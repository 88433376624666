import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useGenericForm} from '../../CustomHooks';
import {useDispatch, useSelector} from "react-redux";
import {userLogin} from "../../redux/actions/user";
import {Box} from "@material-ui/core";
import {red} from "@material-ui/core/colors";
import {selectUser} from "../../redux/reducers/user";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default () => {

  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const {values, handleInputChange, handleSubmit} = useGenericForm(
      {username: '', password: ''},
      (submittedValues) => {
        dispatch(userLogin(submittedValues))
      });
  const classes = useStyles();

  return (
      <Container className={classes.container}maxWidth="xs">
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon/>
          </Avatar>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
                onChange={handleInputChange}
                value={values.username}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Brugernavn"
                name="username"
                autoComplete="username"
                autoFocus
            />
            <TextField
                onChange={handleInputChange}
                value={values.password}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                autoComplete="current-password"
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
            >
              Log ind
            </Button>
          </form>
          <Box color={red}>{user.loginError}</Box>
      </Container>
  );
}
