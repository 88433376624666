import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {indlaesIndtjening} from "../../redux/actions/indtjening";
import Grid from "@material-ui/core/Grid";
import Udvikling from "./Udvikling";
import Potentiale from "./Potentiale";
import ProduktType from "./ProduktType";
import ProduktGruppe from "./ProduktGruppe";
import LoadingIndicator from "../LoadingIndicator";

export default () => {
  const dispatch = useDispatch();
  const indtjening = useSelector(state => state.indtjening);

  if (indtjening.expire < new Date() && !indtjening.indlaeser) {
    dispatch(indlaesIndtjening());
  }

  if (!indtjening || !indtjening.indtjening || !indtjening.indtjening.indtjeningPerioder)
    return <LoadingIndicator/>;

  return (
        <Grid container spacing={2}>
          <Udvikling/>
          <Potentiale/>
          <ProduktType/>
          <ProduktGruppe/>
        </Grid>
  );
};
