import React from 'react';
import {useSelector} from "react-redux";
import DateUtils from "../../util/DateUtils";
import LpnBarChart from "../charts/LpnBarChart";
import IndtjeningBox from "./IndtjeningBox";
import LoadingIndicator from "../LoadingIndicator";

export default () => {
  const indtjening = useSelector(state => state.indtjening);
  if (!indtjening || !indtjening.indtjening || !indtjening.indtjening.indtjeningPerioder)
    return <LoadingIndicator/>

  let data = indtjening.indtjening.indtjeningPerioder.map(item => {
    return {
      name: item.periodeNavn,
      value: item.samletIndtjening / 1000000

    }
  })

  return (
      <IndtjeningBox
          header="Udvikling i forventet helårlig indtjening sammenholdt med samme tidspunkt i tidligere år, i mio. kr."
          footer={"Beregnet pr. " + DateUtils.lpLongDate(indtjening.indtjening.beregningsDato)}
      >
        <LpnBarChart data={data}/>
      </IndtjeningBox>
  );
};
