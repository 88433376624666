import Grid from "@material-ui/core/Grid";
import React, {Fragment} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {indlaesIndtjening} from "../../redux/actions/indtjening";
import {indlaesRangliste} from "../../redux/actions/rangliste";
import {indlaesUdvikling} from "../../redux/actions/udvikling";
import {selectUser} from "../../redux/reducers/user";
import DateUtils from '../../util/DateUtils';
import {formatNumber} from "../../util/FormatUtils";
import LoadingIndicator from "../LoadingIndicator";
import OversigtBox from "./OversigtBox";

export default () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const rangliste = useSelector(state => state.rangliste);
  const indtjening = useSelector(state => state.indtjening);
  const udvikling = useSelector(state => state.udvikling);

  if (rangliste.expire < new Date() && !rangliste.indlaeser) {
    dispatch(indlaesRangliste());
  }
  if (indtjening.expire < new Date() && !indtjening.indlaeser) {
    dispatch(indlaesIndtjening());
  }
  if (udvikling.expire < new Date() && !udvikling.indlaeser) {
    dispatch(indlaesUdvikling());
  }

  if (!indtjening || !indtjening.indtjening || !indtjening.indtjening.indtjeningPerioder) {
    return <LoadingIndicator/>
  }

  return (
      <Grid container spacing={2}>
        {user.userinfo && rangliste.rangliste && visPlacering(user.userinfo.pengeinstitut, rangliste.rangliste) &&
        <Fragment>
          <OversigtBox
              header="Ugeplacering på ranglisten"
              diffValue={rangliste.rangliste && rangliste.rangliste.ugeDiff}
              figure={rangliste.rangliste && rangliste.rangliste.placeringUge}
              footer={"Baseret på salget i uge " + (rangliste.rangliste && rangliste.rangliste.ugeNr) + " " + new Date(rangliste.rangliste && rangliste.rangliste.ranglisteTidspunkt).getFullYear()}
              loading={rangliste.indlaeser}
          />
          <OversigtBox
              header="Samlet placering på ranglisten"
              diffValue={rangliste.rangliste && rangliste.rangliste.aarTilDatoDiff}
              figure={rangliste.rangliste && rangliste.rangliste.placeringAarTilDato}
              footer={"Baseret på salget ÅTD pr." + DateUtils.lpLongDate(rangliste.rangliste && rangliste.rangliste.ranglisteTidspunkt)}
              loading={rangliste.indlaeser}
          />
        </Fragment>
        }
        <OversigtBox
            header="Forventet helårig indtjening"
            figure={indtjening.indtjening && forventetIndtjening(indtjening.indtjening)}
            loading={indtjening.indlaeser}
        />
        <OversigtBox
            header="Uudnyttet indtjeningspotentiale"
            figure={indtjening.indtjening && potentiale(indtjening.indtjening)}
            footer="Baseret på indtjening af top fem bedste pengeinstitutter"
            loading={indtjening.indlaeser}
        />
        <OversigtBox
            header="Letsikring af indtægt ved pension"
            figure={formaterBeloeb(ugeSalg(udvikling.udvikling, 'Pension'))}
            footer={ugeNummer(udvikling.udvikling, 'Pension')}
            loading={udvikling.indlaeser}
        />
        <OversigtBox
            header="Letsikring ved sygdom"
            figure={ugeSalg(udvikling.udvikling, 'Sygdom') + " stk."}
            footer={ugeNummer(udvikling.udvikling, 'Sygdom')}
            loading={udvikling.indlaeser}
        />
        <OversigtBox
            header="Letsikring ved kritisk sygdom"
            figure={ugeSalg(udvikling.udvikling, 'KritiskSygdom') + " stk."}
            footer={ugeNummer(udvikling.udvikling, 'KritiskSygdom')}
            loading={udvikling.indlaeser}
        />
        <OversigtBox
            header="Letsikring af helbred"
            figure={ugeSalg(udvikling.udvikling, 'Helbred') + " stk."}
            footer={ugeNummer(udvikling.udvikling, 'Helbred')}
            loading={udvikling.indlaeser}
        />
        <OversigtBox
            header="Letsikring ved død"
            figure={ugeSalg(udvikling.udvikling, 'Død') + " stk."}
            footer={ugeNummer(udvikling.udvikling, 'Doed')}
            loading={udvikling.indlaeser}
          />
          <OversigtBox
            header="Letsikring til selvstændige"
            figure={ugeSalg(udvikling.udvikling, 'LTS') + " stk."}
            footer={ugeNummer(udvikling.udvikling, 'LTS')}
            loading={udvikling.indlaeser}
        />
      </Grid>
  )
};

function formaterBeloeb(beloeb) {
  if (beloeb >= 100000) {
    return formatNumber(beloeb / 1000000, 2) + " mio. kr.";
  } else {
    return formatNumber(beloeb, 0) + " kr.";
  }
}

function getUgesalg(udvikling, produktGruppeNavn) {
  let salgProduktGrupper = udvikling.salgProduktGrupper;
  let ugeSalg = [...salgProduktGrupper[0].ugeSalg];
  for (var i = 0; i < salgProduktGrupper.length; i++) {
    if (salgProduktGrupper[i].salgProduktGruppeNavn === produktGruppeNavn) {
      ugeSalg = [...salgProduktGrupper[i].ugeSalg];
    }
  }
  return ugeSalg;
}

function ugeNummer(udvikling, produktGruppeNavn) {
  let ugeNr = "";
  let aar = "";

  if (udvikling && udvikling.salgProduktGrupper.length > 0) {
    let ugeSalg = getUgesalg(udvikling, produktGruppeNavn);
    let ugeTekst = produktGruppeNavn === "Pension" ? "Samlet nysalg (indskud og løbende) i uge " : "Solgt i uge ";
    aar = new Date(ugeSalg[ugeSalg.length - 1].periodeFra).getFullYear();

    ugeNr = ugeTekst + ugeSalg[ugeSalg.length - 1].ugeNr + " " + aar;
  }
  return ugeNr;
}

function ugeSalg(udvikling, produktGruppeNavn) {
  let salg = 0;

  if (udvikling && udvikling.salgProduktGrupper.length > 0) {
    let ugeSalg = getUgesalg(udvikling, produktGruppeNavn);
    if (produktGruppeNavn === 'Pension') {
      salg = ugeSalg[ugeSalg.length - 1].beloeb;
    } else {
      salg = ugeSalg[ugeSalg.length - 1].antal;
    }
  }
  return salg;
}

function potentiale(indtjening) {
  let indtjeningPeriode = indtjening.indtjeningPerioder[indtjening.indtjeningPerioder.length - 1];

  let egenIndtjening = indtjeningPeriode.samletIndtjening;
  let top5Indtjening = indtjeningPeriode.top5GennemsnitligIndtjening;
  let potentiale = top5Indtjening - egenIndtjening;
  if (potentiale < 0)
    potentiale = 0;
  return formaterBeloeb(potentiale);
}


function forventetIndtjening(indtjening) {
  let indtjeningPeriode = indtjening.indtjeningPerioder[indtjening.indtjeningPerioder.length - 1];
  return formaterBeloeb(indtjeningPeriode.samletIndtjening);
}

function visPlacering(pengeinstitut, rangliste) {
  if (pengeinstitut.regnr === "0000")
    return false;
  for (let i = 0; i < rangliste.placeringer.length; i++) {
    if (rangliste.placeringer[i].pengeinstitut.id === pengeinstitut.id && rangliste.placeringer[i].antalMedarbejdere > 40) {
      return true;
    }
  }
  return false;
}
